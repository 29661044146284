.section-big-title {
  padding: 100px 0 30px;

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &:after {
      content: '';
      display: block;
      margin-top: 34px;
      height: 102px;
      width: 1px;
      background-color: #c8d8e0;
    }
  }

  &__subtitle {
    margin-bottom: 28px;
  }

  &__title {
    margin-bottom: 24px;
  }
}
