.main-video {
  $width: 818px;
  $height: 482px;
  width: $width;

  &__tablet {
    position: absolute;
    left: percents(456px, $width);
    top: 0;
    width: percents(350px);
    border-radius: 8px;
    background-color: #111;

    &:after {
      content: '';
      display: block;
      padding-top: 128.6%;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      left: 12px;
      border-radius: 2.5px;
      background-color: #fff;
      width: percents(32px, 350px);
      height: 3px;
    }
  }

  &__macbook {
    position: relative;
    top: 35px;

    &-video-background,
    &-video {
      z-index: 1;
      position: absolute;
      width: percents(625px, $width);
      left: percents(98px, $width);
      top: percents(22px, $width);
    }

    &-video-background {
      background-color: #1d1e24;
      height: 76%;
    }

    &-frame {
      position: relative;
      width: 100%;
    }
  }

  &__screen {
    $screen-height: 192px;
    position: absolute;
    left: -12px;
    top: percents(324px, $height);
    background-color: #111;
    width: percents(250px, $width);
    border-radius: 8px 8px 0 0;

    &:before,
    &:after {
      content: '';
      display: block;
    }

    &:before {
      position: absolute;
      height: 4px;
      border-radius: 2px;
      background-color: #fff;
      left: 12px;
      top: percents(16px, $screen-height);
      width: percents(32px, 250px);
    }

    &:after {
      padding-top: 76.8%;
    }

    &-close {
      position: absolute;
      top: percents(14px, $screen-height);
      right: 15px;
      width: 7px;
      height: 7px;
      background-image: inline('cross-white.svg');
      background-size: cover;
      background-position: center center;
    }

    &-video {
      z-index: 2;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }

  @include mq-desktop-small {
    width: 710px;
  }

  @include max-screen(1060px) {
    width: 660px;
  }

  @include mq-tablet {
    max-width: 100%;
  }

  @include mq-mobile {
    &__tablet {
      border-radius: 5px;

      &:before {
        height: 1px;
        top: 11px;
      }
    }

    &__macbook {
      top: 23px;
    }

    &__screen {
      border-radius: 5px 5px 0 0;

      &:before {
        height: 1px;
      }

      &-close {
        right: 11px;
        width: 5px;
        height: 5px;
      }
    }
  }
}
