@import 'constants';
@import 'colors';
@import 'lib/base';
@import 'fonts';
@import 'reset';
@import 'global';
@import 'layout';
@import 'extends';

/***=== COMPONENTS ===***/
@import '../components/page/page';
@import '../components/text/text';
@import '../components/title/title';
@import '../components/styled-list/styled-list';
@import '../components/text-section/text-section';
@import '../components/container/container';
@import '../components/button/button';
@import '../components/header/header';
@import '../components/logo-carousel/logo-carousel';
@import '../components/main-video/main-video';
@import '../components/link-arrow/link-arrow';
@import '../components/fixed-scrolling/fixed-scrolling';
@import '../components/subtitle/subtitle';
@import '../components/book-demo/book-demo';
@import '../components/burger/burger';
@import '../components/mobile-menu/mobile-menu';
@import '../components/logo/logo';
@import '../components/footer/footer';
@import '../components/testimonials/testimonials';
@import '../components/clip-animation/clip-animation';
@import '../components/jump-animation/jump-animation';
@import '../components/brand-protection/brand-protection';
@import '../components/book-screen/book-screen';
@import '../components/back/back';
@import '../components/input-text/input-text';
@import '../components/input-label/input-label';
@import '../components/input-field/input-field';
@import '../components/book-screen/book-demo-form';
@import '../components/checkbox/checkbox';
@import '../components/link-text/link-text';
@import '../components/errors/errors';
@import '../components/success/success';
@import '../components/book-demo-carousel/book-demo-carousel';
@import '../components/circle-rhombus/circle-rhombus';
@import '../components/text-switcher/text-switcher';
@import '../components/privacy-sections/privacy-sections';
@import '../components/text-big/text-big';
@import '../components/circle-with-chevron/circle-with-chevron';
@import '../components/text-with-icon/text-with-icon';
@import '../components/cta-block/cta-block';
@import '../components/common-intro/common-intro';
@import '../components/nav-dropdown/nav-dropdown';
@import '../components/auth-screen/auth-screen';

/***=== SECTIONS ===***/
@import '../components/section-roi/section-roi';
@import '../components/section-number-boxes/section-number-boxes';
@import '../components/section-logos/section-logos';
@import '../components/section-content/section-content';
@import '../components/section-user-stories/section-user-stories';
@import '../components/section-numbers/section-numbers';
@import '../components/section-chosen/section-chosen';
@import '../components/section-features/section-features';
@import '../components/section-land-reg/section-land-reg';
@import '../components/section-with-list/section-with-list';
@import '../components/section-db-features/section-db-features';
@import '../components/section-step-timeline/section-step-timeline';
@import '../components/section-big-title/section-big-title';

/***=== PAGES ===***/
@import './pages/home';
@import './pages/company';
@import './pages/privacy';
@import './pages/case-study';
@import './pages/lettings';
@import './pages/sales';
