* {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*,
*:before,
*:after {
  @include box; }

html, body {
  width: 100%;
  height: 100%;
  cursor: default;
  -webkit-font-smoothing: antialiased; }
//-webkit-text-size-adjust: 100%

body {
  color: $color-dark;
  background: #FFF;
  @include font-family-sans; }

img, fieldset {
  border: 0; }

fieldset legend {
  display: none; }

ul, li {
  list-style-type: none; }

img {
  vertical-align: top; }

table {
  border: 0;
  border-collapse: collapse;

  td {
    padding: 0;
    vertical-align: top; } }

input, select, textarea {
  @include box; }

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none; }

input[type=search] {
  -webkit-appearance: textfield; }

::selection {
  background: #33378f;
  color: #FFFFFF;
  text-shadow: none; }

::-moz-selection {
  background: #33378f;
  color: #FFFFFF;
  text-shadow: none; }

// ::-webkit-scrollbar
// 	width: 6px
// 	height: 6px
// 	background: #eaedf1

// ::-webkit-scrollbar-button
// 	display: none

// ::-webkit-scrollbar-track
// 	background: #eaedf1

// ::-webkit-scrollbar-thumb
// 	background: #5f5f5f

// ::-webkit-scrollbar-thumb:hover
// 	background: #333333

#__bs_notify__ {
  padding: 2px !important;
  font-size: 10px !important;
  opacity: 0.75;
  user-select: none;
  pointer-events: none; }

button {
  user-select: none;
  border-radius: 0; }

.wrapper {
  overflow: hidden;
  min-height: 100%; }

img {
  transition: opacity 0.35s ease-out; }

img:not([src]) {
  opacity: 0; }
