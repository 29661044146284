.fixed-scrolling {
  $video-width: 818px;
  position: relative;

  &__index {
    position: absolute;
    top: 50%;
    right: 0;
    width: 60px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: papayawhip;
    color: $color-dark;
    font-weight: 700;
    z-index: 100;
    font-size: 18px;
  }

  &__video-area-container {
    @include cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 47%;
    height: 100%;
  }

  &__video-area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  &__video-area-container,
  &__video-area {
    background-color: #050505;
  }

  &__mac {
    position: relative;
    max-width: $video-width;
    width: 90%;

    &-video-background {
      background-color: #1d1e24;
      height: 76%;
    }

    &-video-background,
    &-video {
      position: absolute;
      width: percents(625px, $video-width);
      left: percents(98px, $video-width);
      top: percents(10px, $video-width);
    }

    &-video {
      visibility: hidden;
      opacity: 0;
    }

    &-frame {
      position: relative;
      width: 100%;
    }
  }

  &__content {
    padding-left: 53%;
  }

  @include mq-mobile {
    &__video-area-container {
      display: none;
    }

    &__content {
      padding-left: 0;
    }
  }
}

.fixed-scrolling-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -20%;
    height: 1px;
    width: 120%;
    background-color: #c8d8e0;
  }

  &:last-of-type {
    border-bottom: none;

    &:before {
      display: none;
    }
  }

  &__inner {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  &__circle {
    margin-bottom: 42px;
  }

  &__title {
    @include font-family-sans-alt;
    font-size: 32px;
    margin-bottom: 13px;
    font-weight: 700;
  }

  &__text {
    max-width: 480px;
  }

  @include mq-tablet {
    &__inner {
      padding-top: 130px;
      padding-bottom: 120px;
    }
  }

  @include mq-mobile {
    height: auto;

    &:before {
      left: 50%;
      width: 100vw;
      transform: translateX(-50%);
    }

    &__inner {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &__circle {
      margin-bottom: 20px;
      width: 30px;
      height: 30px;
    }

    &__title {
      font-size: 24px;
    }
  }
}
