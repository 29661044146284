.circle-rhombus {
  position: relative;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: rgba($color-blue, 0.15);

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: percents(13px, 37px);
    height: percents(13px, 37px);
    border-radius: 1.5px;
    background-color: $color-blue;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  @include mq-desktop-small {
    width: 34px;
    height: 34px;
  }

  @include mq-tablet {
    width: 32px;
    height: 32px;
  }

  @include mq-mobile {
    width: 28px;
    height: 28px;
  }
}
