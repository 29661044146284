.section-numbers {
  background-color: $color-dark-blue;
  color: #fff;

  &__container {
    position: relative;
    height: 100%;
    padding-top: 118px;
    padding-bottom: 260px;
  }

  &__header {
    text-align: center;
    margin-bottom: 137px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 60px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    margin-bottom: -90px;
  }

  &__col {
    width: 320px;
    margin-bottom: 90px;
  }

  &__item {
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: -102px;
    width: 488px;
  }

  @include mq-desktop-small {
    &__title {
      font-size: 56px;
    }
  }

  @include mq-tablet {
    &__container {
      padding-bottom: 200px;
    }

    &__header {
      margin-bottom: 100px;
    }

    &__title {
      font-size: 48px;
    }

    &__items {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    &__col {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }

  @include mq-mobile {
    &__container {
      padding-top: 80px;
      padding-bottom: 180px;
    }

    &__header {
      margin-bottom: 50px;
    }

    &__title {
      font-size: 30px;
    }

    &__items {
      flex-direction: column;
      margin-bottom: -30px;
    }

    &__col {
      margin-bottom: 30px;
      width: 100%;
    }

    &__image {
      display: none;
    }
  }

  @include mq-mobile-xs {
    &__title {
      font-size: 27px;
    }
  }
}
