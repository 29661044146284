.section-user-stories {
  padding-top: 60px;
  padding-bottom: 100px;

  &__title {
    margin-bottom: 71px;
  }

  &__items {
    display: flex;
    align-items: stretch;
    margin: 0 -14px -28px;
    flex-wrap: wrap;
  }

  &__items-col {
    padding: 0 14px;
    flex: 0 1 33.33%;
    margin-bottom: 28px;
  }

  &__bottom-text {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    font-size: 16px;
  }

  @include mq-desktop-small {
    &__items {
      margin: 0 -10px -20px;
    }

    &__items-col {
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }

  @include mq-tablet {
    padding-bottom: 80px;

    &__title {
      margin-bottom: 50px;
    }

    &__bottom-text {
      margin-top: 60px;
    }
  }

  @include mq-mobile {
    &__items {
      margin-bottom: 0;
      align-items: center;
      flex-direction: column;
    }

    &__items-col {
      flex-basis: auto;
      width: 300px;
      margin-bottom: 40px;
      padding: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.user-story {
  position: relative;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
  padding: 30px 30px 32px;
  height: 100%;

  &__icon {
    position: absolute;
    top: 36px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: rgba($color-blue, 0.15);

    &-house {
      width: percents(11px, 37px);
      height: percents(11px, 37px);
      fill: $color-blue;
    }
  }

  &__title {
    @include font-family-sans-alt;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 15px;
    padding-right: 50px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    color: #c4c4c4;
  }

  &__list {
    margin-top: 36px;

    &-item {
      position: relative;
      align-items: center;
      margin-bottom: 31px;
      font-weight: 700;
      font-size: 18px;
      padding-left: 50px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-image: inline('tick-circle-alt.svg');
        background-size: cover;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__diagrams {
    margin-top: 42px;
  }

  &__diagram {
    margin-bottom: 35px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &._dark {
    background-color: $color-dark;
    color: #fff;

    .user-story {
      &__icon {
        border: 2px solid $color-blue;
        background-color: transparent;

        &-house {
          fill: #fff;
        }
      }

      &__subtitle {
        color: #c0c0cd;
      }

      &__list {
        &-item {
          &:before {
            background-image: inline('tick-circle.svg');
          }
        }
      }
    }
  }

  @include mq-desktop-small {
    padding: 28px 28px 30px;

    &__icon {
      width: 33px;
      height: 33px;
    }

    &__title {
      font-size: 26px;
    }

    &__list {
      &-item {
        margin-bottom: 25px;
        font-size: 17px;
      }
    }
  }

  @include mq-tablet {
    padding: 25px 22px;

    &__icon {
      display: none;
    }

    &__title {
      font-size: 22px;
      padding-right: 0;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__list {
      margin-top: 20px;

      &-item {
        font-size: 15px;
        margin-bottom: 18px;
        padding-left: 32px;

        &:before {
          width: 22px;
          height: 22px;
        }
      }
    }

    &__diagrams {
      margin-top: 25px;
    }
  }

  @include mq-mobile {
    padding: 30px;

    &__title {
      font-size: 26px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__list {
      margin-top: 30px;

      &-item {
        font-size: 17px;
      }
    }

    &__diagrams {
      margin-top: 30px;
    }
  }
}

.user-story-diagram {
  &__bar {
    background-color: #c0c0cd;
    height: 8px;
    border-radius: 8px;
    margin-bottom: 8px;

    &._blue {
      background-color: $color-blue;
    }
  }

  &__value,
  &__caption {
    @include font-family-sans-alt;
    font-weight: 700;
  }

  &__value {
    font-size: 32px;
    font-weight: 700;
  }

  &__caption {
    font-size: 15px;
    color: $color-grey;
  }

  @include mq-tablet {
    &__value {
      font-size: 28px;
    }
  }
}
