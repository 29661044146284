.section-step-timeline {
  padding-top: 120px;
  padding-bottom: 80px;

  &__title {
    margin-bottom: 85px;
  }

  @include mq-tablet {
    padding-top: 110px;
    padding-bottom: 50px;

    &__title {
      margin-bottom: 60px;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 20px;

    &__title {
      margin-bottom: 50px;

      br {
        display: none;
      }
    }
  }
}

.step-timeline {
  position: relative;

  &__items {
    position: relative;
  }

  @include mq-tablet {
    height: auto;

    &__items {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.step-timeline-item {
  @include font-family-sans-alt;
  font-weight: 700;
  display: flex;
  margin-bottom: 27px;

  &:last-child {
    margin-bottom: 0;
  }

  &__circle {
    position: relative;
    top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    border: 2px solid #5359ea;
    border-radius: 50%;
    margin-bottom: 36px;
    color: #a0abb1;
    flex-shrink: 0;
    margin-right: 23px;
  }

  &__text {
    font-size: 22px;
    line-height: 1.5;
    border-bottom: 1px solid #c8d8e0;
    max-width: 780px;
    flex: 1;
    padding-bottom: 30px;
  }

  @include mq-tablet {
    &__text {
      font-size: 20px;
    }
  }

  @include mq-mobile {
    &__circle {
      margin-right: 20px;
    }

    &__text {
      font-size: 18px;
    }
  }
}
