.circle-with-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid #e7e7e7;

  &__icon {
    width: 5px;
    height: 8px;
    fill: $color-blue;
  }
}
