.footer {
  background-color: $color-dark-blue;
  padding-top: 66px;
  padding-bottom: 90px;
  color: #fff;

  &__logo {
    margin-bottom: 47px;
  }

  &__line {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
  }

  &__company,
  &__copy {
    font-size: 14px;
  }

  &__company {
    margin-bottom: 13px;
  }

  &__copy {
    color: #a0abb1;
  }

  &__social {
    display: flex;
    align-items: center;
    margin-top: 83px;
  }

  &__social-link {
    @include cover;
    @include hover-default;
    position: relative;
    display: block;
    margin-right: 20px;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
    }

    &:last-child {
      margin-right: 0;
    }

    &._facebook {
      width: 9px;
      height: 17px;
      background-image: inline('facebook.svg');
    }

    &._twitter {
      width: 16px;
      height: 14px;
      background-image: inline('twitter.svg');
    }

    &._instagram {
      width: 17px;
      height: 17px;
      background-image: inline('instagram.svg');
    }

    &._linkedin {
      top: -1px;
      width: 16px;
      height: 16px;
      background-image: inline('linkedin.svg');
    }
  }

  &__links {
    display: flex;
  }

  &__links-col {
    display: flex;
    flex-direction: column;
    margin-right: vw(188px);

    &:last-of-type {
      margin-right: 0;
    }

    @include mq-desktop-large {
      margin-right: 188px;
    }
  }

  &__link {
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    margin-bottom: 23px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include mq-mobile {
    padding-bottom: 60px;

    &__logo {
      margin-bottom: 40px;
    }

    &__content {
      padding-top: 50px;
      flex-direction: column;
    }

    &__social {
      margin-top: 30px;
    }

    &__col {
      margin-bottom: 50px;
    }

    &__links-col {
      margin-right: 50px;
    }
  }

  @include mq-mobile-xs {
    &__links-col {
      margin-right: 40px;
    }

    &__link {
      font-size: 15px;
    }
  }
}
