.book-demo-carousel {
  position: relative;
  visibility: hidden;
  opacity: 0;
  color: #fff;
  padding-bottom: 60px;
  overflow: hidden;
  background-color: #09090a;
  height: 100%;

  &__slides {
    display: flex;
    background-color: inherit;
  }

  &__slide {
    flex: 1 0 100%;
    background-color: inherit;
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 154px;

    &:before {
      content: '';
      display: block;
      padding-top: 40vh;
      min-height: 400px;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__content {
    text-align: center;
    max-width: 492px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    @include font-family-sans-alt;
    font-size: 38px;
    margin-bottom: 15px;
  }

  &__text {
    color: #c0c0cd;
    font-size: 18px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;

    .swiper-pagination-bullet {
      display: block;
      position: relative;
      width: 16px;
      height: 3px;
      margin: 0 5px;
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
      }

      &:after {
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
      }

      &:before {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        border-radius: 2px;
        background-color: #5c5c5c;
        transition: width $default-transition-duration
            $default-transition-function,
          height $default-transition-duration $default-transition-function,
          background $default-transition-duration $default-transition-function,
          border-radius $default-transition-duration
            $default-transition-function;
      }

      &-active {
        &:before {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #fff;
        }
      }
    }
  }
}
