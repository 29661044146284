.book-demo {
  position: relative;
  background-color: $color-dark-blue;
  color: #fff;

  &__container {
    padding-top: 165px;
    padding-bottom: 117px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    max-width: 470px;
    margin-bottom: 40px;
    color: $color-grey;
  }

  &__button {
    text-transform: uppercase;
    font-size: 17px;
  }

  &__image {
    position: absolute;
    right: -105px;
    bottom: -60px;
    width: 710px;
  }

  @include mq-desktop-small {
    &__container {
      padding-top: 140px;
    }

    &__text {
      max-width: 400px;
    }

    &__image {
      bottom: 30px;
      width: 600px;
    }
  }

  @include mq-tablet {
    &__container {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &__text {
      max-width: 340px;
    }

    &__image {
      width: 500px;
      right: -110px;
    }
  }

  @include mq-mobile {
    &__container {
      padding-bottom: 40px;
    }

    &__image {
      position: relative;
      margin-top: 30px;
      bottom: auto;
      right: -30px;
      max-width: 100%;
    }
  }
}
