.section-chosen {
  padding-top: 137px;
  padding-bottom: 124px;
  text-align: center;
  background: linear-gradient(180deg, rgba(249, 249, 250, 0) 0%, #f9f9fa 47.4%);

  &__subtitle {
    margin-bottom: 22px;
  }

  &__title {
    margin-bottom: 88px;
    max-width: percents(900px);
    margin-left: auto;
    margin-right: auto;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-col {
    display: flex;
    justify-content: center;
    width: 16.66%;
    padding: 0 10px;
    font-size: 0;
  }

  &__item {
    max-width: 135px;
    max-height: 60px;
    height: auto;
  }

  &._dark {
    background-color: $color-pattern;
    background-image: none;
    color: #fff;

    .section-chosen {
      &__subtitle {
        color: $color-blue;
      }

      &__item-col {
        opacity: 0.7;
      }

      &__item {
        filter: brightness(0) invert(1);
      }
    }
  }

  @include mq-tablet {
    padding-top: 80px;
    padding-bottom: 80px;

    &__item-col {
      width: 25%;
    }
  }

  @include mq-mobile {
    &__title {
      max-width: none;
      font-size: 26px;
      margin-bottom: 50px;
    }

    &__items {
      flex-direction: column;
      align-items: center;
    }

    &__item-col {
      width: 100%;
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__item {
      max-height: 50px;
    }
  }
}
