.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  height: 144px;
  user-select: none;
  z-index: 10;
  transition: background-color 0.35s ease-out, transform 0.35s ease-out,
    height 0.35s ease-out;
  will-change: transform;

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('/media/img/pattern-background.jpg');
    background-size: 100%;
    background-position: center top;
    transition: opacity 0.1s ease-out;
    will-change: transform;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    z-index: 1;
  }

  &__logo-link {
    font-size: 0;

    &._disabled {
      pointer-events: none;
    }
  }

  &__logo {
    transition: fill 0.35s ease-out;
  }

  &__navigation {
    display: flex;
    position: relative;

    &-item {
      position: relative;
      margin: 0 37px;

      &._dropdown {
        .header__navigation-item-link {
          &:after {
            @include cover;
            content: '';
            display: block;
            background-image: inline('chevron-down-white.svg');
            width: 9px;
            height: 6px;
            margin-left: 9px;
          }
        }
      }
    }

    &-item-link {
      @include hover-default;
      display: flex;
      align-items: center;
      color: #fff;
      text-decoration: none;
      font-weight: 700;
      font-size: 17px;
      cursor: pointer;
    }
  }

  &__right-section {
    display: flex;
    align-items: center;
  }

  &__additional-link {
    @include hover-default;
    color: rgba($color-grey, 0.7);
    font-size: 14px;
    margin-right: 46px;
    font-weight: 700;
    text-decoration: none;
  }

  &__button {
    text-transform: uppercase;
    font-size: 14px;
    height: 43px;
  }

  &__burger {
    display: none;
  }

  &._scrolled {
    background-color: #fff;
    color: $color-dark;
    transform: translate3d(0px, -100%, 0px);

    .header {
      &__background {
        opacity: 0;
        transition-delay: 0.35s;
      }

      &__logo {
        fill: #000;
      }

      &__navigation-item {
        &._dropdown {
          .header__navigation-item-link {
            &:after {
              background-image: inline('chevron-down-grey.svg');
            }
          }
        }
      }

      &__navigation-item-link {
        color: $color-dark;
      }

      &__additional-link {
        color: $color-dark-grey;
      }

      &__burger {
        .burger__line {
          background-color: $color-dark;
        }
      }
    }

    &._visible {
      transform: translate3d(0px, 0px, 0px);
      box-shadow: 0 0 6px rgba($color-dark, 0.15);
      height: 106px;
    }
  }

  &._transparent {
    .header {
      &__background {
        display: none;
      }
    }
  }

  @include mq-desktop-small {
    &__navigation-link {
      margin: 0 23px;
    }

    &__additional-link {
      margin-right: 32px;
    }
  }

  @include mq-tablet {
    height: 100px;

    &__navigation {
      left: auto;

      &-link {
        margin: 0 14px;
        font-size: 15px;
      }
    }

    &__additional-link {
      margin-right: 22px;
    }

    &__button {
      font-size: 13px;
      height: 36px;
    }

    &__navigation {
      display: none;
    }

    &__right-section {
      display: none;
    }

    &__burger {
      display: block;
    }

    &._scrolled {
      &._visible {
        height: 100px;
      }
    }
  }

  @include mq-mobile {
    height: 80px;

    &._scrolled {
      &._visible {
        height: 80px;
      }
    }
  }
}
