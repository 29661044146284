.section-content {
  padding-top: 140px;
  padding-bottom: 70px;

  &__container {
    display: flex;
  }

  &__col {
    &._text {
      display: flex;
      align-items: center;
      width: 58.5%;
      padding-top: 10px;
      padding-right: 50px;
    }

    &._visual {
      position: relative;
      width: 41.5%;
    }
  }

  &__text-content {
    padding-bottom: 100px;
  }

  &__title {
    font-size: 60px;
    margin-bottom: 22px;
  }

  &__text {
    max-width: 80%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 125px;
    max-width: 600px;
    margin-bottom: -48px;
  }

  &__list-item {
    width: 50%;
    margin-bottom: 48px;
  }

  &__link-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
  }

  &__visual {
    width: vw(730px);
  }

  &__media {
    max-width: 100%;
  }

  &__link {
    font-size: 16px;
  }

  &._dark {
    border-top: 3px solid $color-blue;
    background-color: $color-dark-blue;
    color: #fff;
  }

  &._text-content-narrower {
    .section-content {
      &__col {
        &._text {
          width: 53%;
        }

        &._visual {
          width: 47%;
        }
      }
    }
  }

  &._reverse {
    .section-content {
      &__container {
        flex-direction: row-reverse;
      }

      &__col {
        &._text {
          padding-right: 0;
          padding-left: 50px;
        }
      }

      &__link-container {
        justify-content: flex-start;
      }

      &__visual {
        position: relative;
        left: 100%;
        transform: translateX(-100%);
        text-align: right;
      }
    }
  }

  @include mq-desktop-small {
    &__title {
      font-size: 48px;
    }
  }

  @include mq-tablet {
    padding-top: 100px;
    padding-bottom: 90px;

    &__col {
      &._text {
        padding-right: 40px;
      }
    }

    &__title {
      font-size: 32px;
    }

    &__text {
      max-width: 90%;
    }

    &__link-container {
      margin-bottom: 20px;
    }

    &__list {
      margin-top: 150px;
      width: 530px;
    }

    &._reverse {
      .section-content {
        &__col {
          &._text {
            padding-right: 0;
            padding-left: 40px;
          }
        }
      }
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__container {
      flex-direction: column;
    }

    &__col {
      &._text,
      &._visual {
        width: auto;
      }

      &._text {
        margin-bottom: 32px;
      }
    }

    &__text-content {
      padding-bottom: 0;
    }

    &__link-container {
      margin-bottom: 40px;
      justify-content: flex-start;
    }

    &__visual {
      width: 90%;
    }

    &__list {
      margin-top: 50px;
      margin-bottom: -20px;
    }

    &__list-item {
      width: 100%;
    }

    &._text-content-narrower {
      .section-content {
        &__col {
          &._text,
          &._visual {
            width: auto;
          }
        }
      }
    }

    &._reverse {
      .section-content {
        &__container {
          flex-direction: column;
        }

        &__col {
          &._text {
            padding-left: 0;
          }
        }

        &__visual {
          left: auto;
          transform: none;
          text-align: left;
        }
      }
    }
  }
}
