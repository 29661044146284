.section-number-boxes {
  padding-bottom: 64px;

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -23px;

    &-col {
      padding: 0 23px;
      width: 25%;
    }
  }

  &__annotations {
    margin-top: 37px;

    &:before {
      content: '';
      display: block;
      height: 1px;
      background-color: #c8d8e0;
      width: percents(560px);
      margin-bottom: 25px;
    }

    &-cols {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex: 0 1;
      justify-content: flex-start;
      margin: 0 -23px;
    }

    &-col {
      width: 25%;
      padding: 0 23px;
    }
  }

  &__annotation {
    position: relative;
    font-size: 13px;
    padding-left: 7px;
    line-height: 1.65;

    &-asterisk {
      position: absolute;
      left: 3px;
      color: $color-green;
      transform: translateX(-100%);
      font-weight: 700;
    }
  }

  @include mq-desktop-small {
    &__items {
      margin: 0 -10px;

      &-col {
        padding: 0 10px;
      }
    }
  }

  @include mq-tablet {
    &__items {
      margin-bottom: -30px;
      justify-content: center;

      &-col {
        width: 45%;
        margin-bottom: 30px;
      }
    }

    &__annotations {
      &:before {
        position: relative;
        left: 50%;
        margin-left: -45%;
        width: 90%;
      }

      &-cols {
        justify-content: center;
        margin-bottom: -20px;
      }

      &-col {
        width: 45%;
        margin-bottom: 20px;
      }
    }
  }

  @include mq-mobile {
    padding-bottom: 40px;

    &__items {
      &-col {
        width: 100%;
        max-width: 260px;
      }
    }

    &__annotations {
      &-col {
        width: 90%;
      }
    }

    &__annotation {
      padding-left: 12px;
    }
  }
}

.section-number-boxes-item {
  background-color: #fff;
  box-shadow: 0 10px 50px rgba(#000, 0.05);
  padding: 37px 31px 27px;

  &__tick {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    margin-bottom: 24px;
    border: 2px solid #eaedef;

    &-icon {
      width: 13px;
      height: 10px;
      fill: $color-blue;
    }
  }

  &__value {
    @include font-family-sans-alt;
    position: relative;
    color: $color-blue;
    font-weight: 700;
    font-size: 36px;

    &:after {
      content: '';
      display: block;
      height: 1px;
      max-width: 124px;
      background-color: #c8d8e0;
      margin-top: 20px;
      margin-bottom: 24px;
    }

    &-units {
      @include font-family-sans;
      font-size: em(18px, 36px);
      margin-left: 4px;
    }

    &-annotation {
      position: absolute;
      right: 0;
      top: -0.5em;
      margin-left: 8px;
      color: $color-green;
      font-size: 20px;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.6;
  }

  @include mq-desktop-small {
    padding: 30px 22px 22px;

    &__tick {
      margin-bottom: 17px;
    }

    &__value {
      font-size: 32px;
    }
  }

  @include mq-tablet {
    padding-bottom: 30px;

    &__value {
      &:after {
        max-width: none;
        width: 66%;
      }
    }
  }
}
