$chevron-transition: transform 0.1s ease-out;

.button {
  @include button-reset;
  @extend .transition-active;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.3);
  height: 54px;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
  background-clip: padding-box;
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.5px;
  padding: 1px 20px 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.8);
    transition: none;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.25);
    border-color: rgba(255, 255, 255, 0.25);

    .button__chevron-right {
      transform: translateX(1px);
      transition: none;
    }
  }

  &__chevron-right {
    margin-left: 13px;
    width: 6px;
    height: 9px;
    fill: #fff;
    transition: $chevron-transition,
      fill $default-transition-duration $default-transition-function;
  }

  &._chevron-right {
    padding-left: em(18px, 14px);
    padding-right: em(13px, 14px);
  }

  &._large-chevron {
    .button__chevron-right {
      width: 7px;
      height: 10px;
      margin-left: 20px;
    }
  }

  &._blue-border {
    border-color: $color-blue;

    &:hover {
      background-color: $color-blue;
      color: #fff;

      .button__chevron-right {
        fill: #fff;
      }
    }

    &:active {
      background-color: rgba($color-blue, 0.9);
      border-color: rgba($color-blue, 0.9);
    }
  }

  &._white-border {
    border-color: #fff;

    &:hover {
      background-color: #fff;
      color: $color-blue;
    }

    &:active {
      background-color: rgba(#fff, 0.9);
      border-color: rgba(#fff, 0.9);
    }
  }

  &._blue {
    background-color: $color-blue;
    border-color: $color-blue;

    &:hover {
      color: #fff;
      background-color: rgba($color-blue, 0.9);
      border-color: rgba($color-blue, 0.9);
    }

    &:active {
      background-color: rgba($color-blue, 0.8);
      border-color: rgba($color-blue, 0.8);
    }
  }

  &._white {
    background-color: #fff;
    border-color: #fff;
    color: $color-blue;

    .button__chevron-right {
      fill: $color-blue;
    }

    &:hover {
      background-color: rgba(#fff, 0.9);
      border-color: rgba(#fff, 0.9);
    }

    &:active {
      background-color: rgba(#fff, 0.8);
      border-color: rgba(#fff, 0.8);
    }
  }

  &._color-dark {
    color: $color-dark;

    &:hover {
      .button__chevron-right {
        fill: #fff;
        transition: $chevron-transition;
      }
    }

    .button__chevron-right {
      fill: $color-blue;
    }
  }

  &._blue-chevron {
    .button__chevron-right {
      fill: $color-blue;
    }
  }

  &._shadow {
    box-shadow: 0 7px 12px rgba($color-blue, 0.4);
  }

  @include mq-mobile {
    height: 46px;
    font-size: 14px;
  }
}
