.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: #000;
  color: #fff;
  z-index: 10;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
    }
  }

  &__logo {
    width: 125px;
    height: 17px;
  }

  &__close {
    @include cover;
    position: fixed;
    margin-right: 2px;
    right: $offset-side-sm;
    width: 20px;
    height: 20px;
    background-image: inline('cross-white.svg');
    z-index: 2;
  }

  &__items {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__item {
    margin-bottom: 24px;
  }

  &__item-link {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 22px;

    &-arrow {
      margin-left: 10px;
      width: 7px;
      height: 11px;
      fill: #fff;
    }
  }

  &__button {
    margin-top: 20px;
    font-size: 16px;
  }

  &__back {
    @include hover-default;
    fill: #fff;
    width: 28px;
    height: 30px;
    cursor: pointer;
  }

  &__sub {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
  }

  @include mq-mobile {
    &__header-container {
      height: 80px;
    }
  }
}
