@mixin button-reset {
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
