.link-text {
  @include hover-default;
  color: $color-blue;
  text-decoration: none;
  font-weight: 700;
  background-image: linear-gradient($color-blue, $color-blue);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: center bottom;
}
