.grid-col {
  width: percents($grid-col-width);
  padding-right: 30px;

  @include mq-mobile {
    width: auto;
    padding-right: 0;
  }
}

.hidden-xs {
  @include mq-mobile {
    display: none !important;
  }
}

.visible-xs {
  display: none;

  @include mq-mobile {
    display: block;
  }
}
