.logo {
  width: 150px;
  height: 17px;
  fill: #fff;

  @include mq-tablet {
    width: 125px;
    height: 14px;
  }

  &._dark {
    fill: $color-dark;
  }
}
