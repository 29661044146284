.checkbox {
  position: relative;
  user-select: none;

  &__input {
    position: absolute;
    visibility: hidden;
    opacity: 0;

    &:checked {
      + .checkbox__label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    position: relative;
    display: block;
    color: $color-dark-grey;
    font-size: 14px;
    padding-left: 35px;
    line-height: 1.5;

    &:active {
      &:after {
        transition-duration: 0.05s;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 1px solid #a0abb1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 2px;
      top: 4px;
      width: 15px;
      height: 12px;
      background-image: inline('tick.svg');
      background-size: cover;
      background-position: center center;
      opacity: 0;
      transition: opacity 0.25s ease-out;
    }
  }

  &__required {
    &:after {
      @include font-family-sans-alt;
      content: '*';
      position: absolute;
      color: $color-red;
      margin-top: -2px;
      margin-left: 4px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  &._bold {
    font-weight: 700;

    .checkbox__label {
      color: $color-dark;
    }
  }
}
