.styled-list {
  &__item {
    position: relative;
    font-size: 22px;
    margin-bottom: 32px;
    padding-left: 60px;
    font-weight: 700;
    line-height: 1.65;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      width: 34px;
      height: 34px;
      background-image: inline('tick-circle-alt2.svg');
      background-size: cover;
    }
  }

  &._two-cols {
    display: flex;
    flex-wrap: wrap;

    .styled-list {
      &__item {
        width: 50%;
        margin-bottom: 20px;
        padding-right: 10px;

        &:nth-last-child(2),
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &._bigger-font-size {
    .styled-list__item {
      font-size: 26px;
    }
  }

  @include mq-tablet {
    &__item {
      font-size: 20px;
    }

    &._two-cols {
      display: block;

      .styled-list {
        &__item {
          width: auto;

          &,
          &:nth-last-child(2),
          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    &._bigger-font-size {
      .styled-list__item {
        font-size: 22px;
      }
    }
  }
}
