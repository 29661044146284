.section-features {
  padding-top: 105px;
  padding-bottom: 112px;
  background-color: $color-dark-blue;
  color: #fff;
  border-top: 3px solid $color-blue;

  &__subtitle {
    text-align: center;
    margin-bottom: 25px;
  }

  &__title {
    text-align: center;
    margin-bottom: 44px;
    font-size: 60px;
  }

  &__text,
  &__center-text {
    text-align: center;
    font-size: 20px;
  }

  &__text {
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  }

  &__center-text {
    max-width: 775px;
    margin: 115px auto 107px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1055px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -60px;
  }

  &__items-col {
    display: flex;
    margin-bottom: 60px;
    padding: 0 5px;
    width: 28%;
  }

  @include mq-tablet {
    &__text,
    &__center-text {
      max-width: 640px;
    }

    &__items {
      justify-content: center;
    }

    &__items-col {
      display: flex;
      justify-content: center;
      width: 300px;
      padding-right: 10px;
    }

    &__item {
      width: auto;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__title {
      font-size: 48px;
    }

    &__text {
      margin-bottom: 80px;
    }

    &__items {
      flex-direction: column;
      align-items: center;
    }

    &__items-col {
      width: 260px;
      justify-content: flex-start;
      padding-right: 0;
    }

    &__center-text {
      margin: 80px 0;
    }
  }
}
