.common-intro {
  position: relative;
  text-align: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: vw(-160px);
    width: 100%;
    height: 100%;
    background-image: url('/media/img/pattern-houses2.jpg');
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
  }

  &__title {
    font-size: 72px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 20px;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    &:first-of-type {
      margin-right: 31px;
    }
  }

  &__bottom-text {
    font-size: 16px;
  }

  &__image {
    margin-top: 115px;
    max-width: 90%;
  }

  &._dark {
    position: static;
    color: #fff;
    background-color: $color-pattern;

    &:before {
      top: 0;
      @include pattern-background;
    }
  }

  @include mq-desktop-small {
    &__title {
      font-size: 60px;
    }
  }

  @include mq-tablet {
    &__title {
      font-size: 50px;
    }

    &__image {
      margin-top: 100px;
    }
  }

  @include mq-mobile {
    &__title {
      font-size: 36px;
    }

    &__button {
      &:first-of-type {
        margin-right: 20px;
      }
    }

    &__image {
      margin-top: 60px;
      max-width: 100%;
    }
  }

  @include mq-mobile-xs {
    &__title {
      font-size: 32px;
    }

    &__buttons {
      flex-direction: column;
      align-items: center;
    }

    &__button {
      &:first-of-type {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
  }
}
