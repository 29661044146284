.case-intro {
  $content-width: 660px;

  &__container {
    position: relative;
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 34px;
    padding-bottom: 60px;
    padding-right: 60px;
    width: percents($content-width);
  }

  &__title {
    font-size: 48px;
    margin-bottom: 12px;
  }

  &__text {
    max-width: 580px;
    margin-bottom: 60px;
    padding-right: 30px;
  }

  &__visual {
    position: relative;

    &-content {
      position: absolute;
      left: 0;
      top: 36px;
      width: vw(681px);
    }
  }

  &__media {
    display: block;
    max-width: 100%;
  }

  @include mq-desktop-small {
    &__content {
      width: 60%;
    }

    &__title {
      font-size: 52px;
    }

    &__text {
      max-width: 550px;
    }

    &__visual {
      &-content {
        left: -40px;
      }
    }
  }

  @include mq-tablet {
    &__content {
      padding-top: 0;
      padding-right: 60px;
    }

    &__title {
      font-size: 36px;
    }

    &__text {
      margin-bottom: 45px;
    }
  }

  @include mq-mobile {
    padding-bottom: 60px;

    &__container {
      display: block;
    }

    &__content {
      width: auto;
      padding-right: 0;
    }

    &__title {
      font-size: 32px;
    }

    &__visual {
      &-content {
        position: static;
        width: 90%;
      }
    }
  }
}
