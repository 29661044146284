#password-reset {
  display: none;
}

.auth-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #fff;
  background-image: url('/media/img/pattern-houses2.jpg');
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &__content {
    min-height: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__logo {
    margin-bottom: 87px;
  }

  &__box {
    padding: 38px;
    background-color: #fff;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
    max-width: 460px;
    width: 100%;
  }

  &__bottom-text {
    margin-top: 18px;
    color: #919191;
  }

  @include mq-mobile {
    &__logo {
      margin-bottom: 60px;
    }

    &__box {
      padding: 25px;
    }
  }
}

.auth-box {
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 36px;
  }

  &__subtitle {
    text-align: center;
    line-height: 1.6;
    color: #666;
    margin-bottom: 38px;
  }

  &__input {
    margin-bottom: 28px;
  }

  &__flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    color: #666666;
    font-size: 14px;
    text-decoration: underline;
  }

  &__button {
    margin-top: 26px;
    width: 100%;
  }

  &__back-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__back {
    text-align: center;
    color: #666;
  }

  &__success-message {
    display: flex;
    justify-content: center;
    color: $color-green;
    font-size: 16px;
    margin-bottom: 60px;

    &-inner {
      display: flex;
      align-items: center;
    }

    &-icon {
      fill: $color-green;
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }

  @include mq-mobile {
    &__flex-container {
      flex-direction: column;
    }

    &__remember-me {
      margin-bottom: 10px;
    }
  }
}
