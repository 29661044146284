.nav-dropdown {
  position: absolute;
  left: 0;
  top: 36px;
  width: 408px;
  visibility: hidden;
  opacity: 0;
  will-change: opacity;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -36px;
    left: 0;
    height: 36px;
    width: 150px;
  }

  &__box {
    background-color: #fff;
    border-radius: 4px;
    border-top: 5px solid $color-blue;
    color: $color-dark;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }
}

.nav-dropdown-item {
  @include animated;
  display: flex;
  align-items: center;
  padding: 21px 20px;
  border-radius: 4px;
  width: 50%;
  transition: background-color 0.25s ease-in;
  text-decoration: none;
  color: $color-dark;

  &:hover {
    background-color: $color-blue;
    color: #fff;
    transition: none;

    .nav-dropdown-item {
      &__icon,
      &__title,
      &__text {
        transition: none;
      }

      &__icon {
        border-color: rgba(#fff, 0.5);

        .el {
          fill: #fff;
          stroke: #fff;
          transition: none;

          &._no-hover-fill {
            fill: $color-blue;
          }
        }
      }

      &__text {
        color: #fff;
      }
    }
  }

  &:active {
    opacity: 0.8;
  }

  &:first-of-type,
  &:nth-of-type(2) {
    border-radius: 0 0 4px 4px;
  }

  &__icon {
    margin-right: 18px;

    .el {
      transition: fill 0.35s ease-in-out, stroke 0.35s ease-in-out;
    }

    &._new-agent {
      width: 29px;
      height: 28px;
    }

    &._local {
      width: 24px;
      height: 24px;
    }

    &._regional {
      width: 27px;
      height: 27px;
    }

    &._national {
      width: 30px;
      height: 24px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 700;
    transition: color 0.35s ease-in-out;
  }

  &__text {
    @extend .text;
    font-size: 14px;
    font-weight: 400;
    transition: color 0.35s ease-in-out;
  }
}
