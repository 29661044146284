.section-with-list {
  padding-top: 142px;
  padding-bottom: 62px;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__grid-col {
    flex-shrink: 0;
  }

  &__title {
    margin-bottom: 45px;
    font-size: 60px;
  }

  &__visual {
    position: relative;
    top: -33px;
    width: percents(530px);
  }

  &__image {
    position: absolute;
  }

  &__list {
    margin-bottom: 40px;
    max-width: 500px;

    &:last-child {
      margin-bottom: 0;
    }

    &._two-cols {
      max-width: none;
    }
  }

  &__text {
    margin-bottom: 40px;
    max-width: 490px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__bottom-text {
    margin-top: 81px;
    text-align: center;
  }

  &._dark {
    color: #fff;
    background-color: $color-pattern;
  }

  &._visual-offset-top {
    .section-with-list__visual {
      top: 40px;
    }
  }

  &._image-closer {
    .section-with-list__visual {
      width: percents(650px);
    }
  }

  &._reverse {
    .section-with-list {
      &__container {
        flex-direction: row-reverse;
      }

      &__grid-col {
        padding-right: 0;
        width: percents(500px);
      }

      &__visual {
        width: 50%;
      }

      &__image {
        position: relative;
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }

  @include mq-desktop-small {
    &__title {
      font-size: 52px;
    }
  }

  @include mq-tablet {
    padding-top: 90px;

    &__title {
      font-size: 44px;
    }

    &__grid-col {
      width: 57%;
    }

    &__visual {
      top: 30px;
    }

    &__image {
      max-width: 150%;
    }

    &._reverse {
      .section-with-list {
        &__grid-col {
          width: percents(600px);
        }

        &__visual {
          width: 45%;
        }
      }
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 20px;

    &__container {
      display: block;
    }

    &__grid-col {
      margin-bottom: 50px;
      width: auto;
    }

    &__title {
      font-size: 38px;
      margin-bottom: 40px;
    }

    &__visual {
      top: auto;
      width: auto;
    }

    &__image {
      position: static;
    }

    &__bottom-text {
      margin-top: 50px;
    }

    &._visual-offset-top {
      .section-with-list__visual {
        top: auto;
      }
    }

    &._image-closer {
      .section-with-list {
        &__visual {
          width: auto;
        }
      }
    }

    &._reverse {
      .section-with-list {
        &__grid-col,
        &__visual {
          width: auto;
        }
      }
    }
  }
}
