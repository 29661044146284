.input-field {
  position: relative;
  border: 1px solid #a0abb1;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  display: flex;

  &__input {
    @include input-reset;
    padding: 0 8px;
    width: 100%;
    flex-shrink: 1;
  }

  &__show-password {
    @include hover-default;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
    }

    svg {
      width: 19px;
      height: 11px;
    }
  }

  &[data-required] {
    &:after {
      @include font-family-sans-alt;
      font-weight: 700;
      content: '*';
      position: absolute;
      right: 11px;
      top: calc(50% - 8px);
      display: block;
      font-size: 18px;
      color: $color-red;
      opacity: 0;
      pointer-events: none;
      transition: opacity $default-transition-duration
        $default-transition-function;
    }
  }

  &._error-required {
    &[data-required] {
      &:after {
        opacity: 1;
      }
    }
  }
}

.phone-code {
  height: 100%;

  &__current {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;
    width: 53px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 30px;
      width: 1px;
      background-color: #a0abb1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 7px;
      background-image: inline('dropdown-arrow-grey.svg');
      width: 5px;
      height: 5px;
      background-size: cover;
    }
  }

  &__select {
    @include font-family-sans;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    font-size: 16px;
    color: #a0abb1;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }
}
