.page {
  &._header-padding {
    padding-top: 245px;
  }

  @include mq-tablet {
    &._header-padding {
      padding-top: 200px;
    }
  }

  @include mq-mobile {
    &._header-padding {
      padding-top: 155px;
    }
  }
}
