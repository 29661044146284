.about-intro {
  position: relative;
  padding-bottom: vw(245px);
  border-bottom: 3px solid $color-blue;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: vw(-160px);
    width: 100%;
    height: calc(100% + #{vw(160px)});
    background-image: url('/media/img/pattern-houses.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  &__header {
    text-align: center;
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    position: relative;
    margin-bottom: 49px;

    .br-mobile {
      display: none;
    }
  }

  &__text {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq-desktop-large {
    padding-bottom: 245px;
  }

  @include mq-desktop-small {
  }

  @include mq-tablet {
    &__title {
      margin-bottom: 30px;
    }

    &__text {
      max-width: 80%;
    }
  }

  @include mq-mobile {
    &__title {
      .br-mobile {
        display: inline;
      }

      &._big {
        font-size: 32px;
      }
    }
  }
}

.section-text-cta {
  padding-top: 80px;
  padding-bottom: 65px;

  &__container {
    display: flex;
  }

  &__content {
    position: relative;
  }

  &__text-big {
    max-width: 430px;
  }

  &__text {
    max-width: 480px;
    margin-bottom: 50px;
    font-size: 17px;
  }

  &__button {
    position: absolute;
    bottom: 0;
  }

  @include mq-tablet {
    text-align: center;

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__grid-col {
      width: auto;
      padding-right: 0;
    }

    &__text-big,
    &__text {
      margin-bottom: 30px;
    }

    &__text-big {
      max-width: 560px;

      br {
        display: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button {
      position: static;
    }
  }

  @include mq-mobile {
    &__text-big {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 25px;
    }
  }
}

.core-values {
  padding-top: 67px;
  padding-bottom: vw(200px);

  &__header {
    margin-bottom: 200px;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__title {
  }

  &__text {
    @include font-family-sans-alt;
    font-size: 30px;
    font-weight: 700;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq-desktop-large {
    padding-bottom: 200px;
  }

  @include mq-tablet {
    &__header {
      margin-bottom: 140px;
    }

    &__subtitle {
      margin-bottom: 20px;
    }
  }

  @include mq-mobile {
    padding-top: 40px;

    &__header {
      margin-bottom: 100px;
    }
  }
}

.section-awards {
  padding-top: 154px;
  padding-bottom: 90px;

  &__container {
    display: flex;
    flex-wrap: wrap;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #c8d8e0;
      margin-top: 103px;
    }
  }

  &__title {
    max-width: 350px;
    margin-right: percents(210px);
  }

  @include mq-tablet {
    padding-top: 110px;

    &__container {
      &:after {
        margin-top: 80px;
      }
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 100px;

    &__container {
      display: block;

      &:after {
        display: none;
      }
    }

    &__title {
      margin-bottom: 50px;
    }
  }
}

.section-awards-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 45px;

  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 217px;
    margin-top: 20px;
    background-color: #c8d8e0;
  }

  &:last-of-type {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  &__link {
    @include animated;
    text-decoration: none;
    color: $color-dark;

    &:hover {
      color: rgba($color-dark, 0.8);

      .section-awards-item__title-circle {
        transform: translateX(3px);
        transition-duration: 0.1s;
      }
    }

    &:active {
      .section-awards-item__title-circle {
        transform: translateX(5px);
      }
    }
  }

  &__title {
    @include font-family-sans-alt;
    position: relative;
    display: flex;
    font-size: 32px;
    margin-bottom: 20px;
    max-width: 350px;
    padding-right: 45px;

    &-circle {
      position: absolute;
      right: 0;
      top: -1px;
      transition: transform 0.35s ease-out;
    }
  }

  &__text {
    max-width: 355px;
  }

  @include mq-desktop-small {
    &__title {
      font-size: 28px;
      max-width: 320px;
    }
  }

  @include mq-tablet {
    &__title {
      font-size: 26px;
      max-width: 280px;
    }
  }

  @include mq-mobile {
    &__title {
      flex-direction: row-reverse;
      max-width: none;
    }
  }
}

.section-news {
  padding-bottom: 120px;

  &__subtitle {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 70px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -24px;
  }

  &__col {
    width: 33.33%;
    padding: 0 24px;
  }

  &__text {
    margin-top: 45px;
    font-size: 16px;
  }

  @include mq-tablet {
    padding-bottom: 100px;

    &__items {
      margin: 0 -10px;
    }

    &__col {
      padding: 0 10px;
    }
  }

  @include mq-mobile {
    padding-bottom: 80px;

    &__subtitle {
      margin-bottom: 15px;
    }

    &__title {
      margin-bottom: 50px;
    }

    &__items {
      margin: 0 0 -30px;
    }

    &__col {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
      max-width: 260px;
    }
  }
}

.section-news-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
  height: 377px;
  padding: 108px 42px 60px;

  &__logo {
    position: absolute;
    top: 65px;
    transform: translateY(-50%);
  }

  &__link {
    @include hover-default;
    position: absolute;
    bottom: 35px;
  }

  @include mq-tablet {
    height: 320px;
    padding: 110px 23px 30px;

    &__text {
      font-size: 16px;
    }
  }
}
