.section-logos {
  padding-top: 160px;
  padding-bottom: 160px;

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -125px;
  }

  &__col {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16.66%;
    margin-bottom: 125px;
  }

  &__item {
    opacity: 0.8;
    max-width: 70%;
    height: auto;
  }

  &._dark-background {
    background-color: $color-dark-blue;

    .section-logos {
      &__item {
        opacity: 1;
      }
    }
  }

  &._alt-layout {
    .section-logos {
      &__col {
        width: 25%;
      }
    }
  }

  @include mq-desktop-small {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  @include mq-tablet {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__items {
      margin-bottom: -80px;
    }

    &._alt-layout,
    & {
      .section-logos {
        &__col {
          width: 50%;
          margin-bottom: 80px;
        }
      }
    }
  }

  @include mq-mobile-xs {
    &__col {
      width: 100%;
    }
  }
}
