[data-animation='clip'] {
  visibility: hidden;
  opacity: 0;

  .clip-element {
    display: block;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
  }
}
