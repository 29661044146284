.section-roi {
  position: relative;
  color: #fff;
  padding-top: 105px;

  &:before {
    @include pattern-background;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__container {
    position: relative;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 125px;

    &-col {
      width: percents(540px);
    }
  }

  &__title {
    margin-bottom: 23px;
  }

  &__button {
    position: relative;
    top: 15px;
    right: 17px;
  }

  &__text {
    color: $color-grey;
  }

  &__content {
    position: relative;
    background-color: #fff;
    color: $color-dark;
    padding-bottom: 30px;

    &-container {
      position: relative;
      display: flex;
      align-items: flex-start;
    }
  }

  &__box {
    position: relative;
    top: -40px;
    margin-right: percents(145px);
    flex: 1 0 auto;
  }

  &__features {
    padding-top: 80px;
  }

  @include mq-desktop-small {
    &__box {
      margin-right: percents(130px);
    }

    &__features {
      padding-top: 60px;
    }
  }

  @include mq-tablet {
    padding-top: 110px;

    &__box {
      margin-right: 0;
      margin-bottom: 60px;
    }

    &__content {
      padding-bottom: 90px;

      &-container {
        flex-direction: column;
      }
    }

    &__features {
      padding-top: 0;
    }
  }

  @include mq-mobile {
    padding-top: 80px;

    &__header {
      display: block;
      margin-bottom: 90px;

      &-col {
        width: auto;
        margin-bottom: 30px;
      }
    }

    &__box {
      margin-bottom: 20px;
    }

    &__button {
      position: static;
    }

    &__content {
      padding-bottom: 80px;

      &-container {
      }
    }
  }
}

.roi-box {
  position: relative;
  display: flex;
  max-width: 650px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
    width: 91.7%;
    height: 277px;
    border-radius: 20px;
    filter: blur(50px);
    background-image: linear-gradient(
      to right,
      rgba(#878787, 0.5) 50%,
      rgba($color-blue, 0.5) 50%
    );
    will-change: transform;
  }

  &__col {
    position: relative;

    &:first-of-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 70px;
      padding-bottom: 31px;
      width: 53%;
      background-color: #fff;
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 70px 26px 40px 32px;
      width: 47%;
      background-color: $color-blue;
      border-radius: 0 8px 8px 0;
    }
  }

  &__circle {
    margin-bottom: 70px;
  }

  &__text {
    font-size: 16px;
    max-width: 230px;
    text-align: center;
  }

  &__text-large {
    @include font-family-sans-alt;
    line-height: 1.36;
    font-size: 28px;
    color: #fff;
    font-weight: 700;
  }

  &__button {
    max-width: 224px;
  }

  @include mq-desktop-small {
    max-width: 590px;

    &__text-large {
      font-size: 26px;
    }
  }

  @include mq-mobile {
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    &__col {
      &:first-of-type,
      &:last-of-type {
        border-radius: 0;
        width: auto;
        padding: 30px;
      }
    }

    &__circle {
      margin-bottom: 30px;
    }

    &__text-large {
      margin-bottom: 30px;
    }
  }
}

.roi-circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 214px;
  height: 214px;
  text-align: center;
  padding-top: 30px;
  user-select: none;

  &__border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__circle {
    position: relative;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: rgba($color-blue, 0.15);
    margin-bottom: 12px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: $color-blue;
      width: 12px;
      height: 12px;
    }
  }

  &__value,
  &__caption {
    color: #3f3f3f;
  }

  &__value {
    @include font-family-condensed;
    display: flex;
    align-items: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 9px;

    &-units {
      font-size: 25px;
      line-height: 1;
      margin-left: 5px;
    }
  }

  &__caption {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 18px;
  }

  &__lines {
    &:before,
    &:after {
      content: '';
      display: block;
      height: 4px;
      border-radius: 2px;
      background-color: rgba(#3f3f3f, 0.1);
      margin-left: auto;
      margin-right: auto;
    }

    &:before {
      margin-bottom: 6px;
      width: 87px;
    }

    &:after {
      width: 58px;
    }
  }
}

.section-roi-feature {
  position: relative;
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: -22px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #e5e6fc;
    transform: translateX(-100%);

    &:before {
      content: '';
      display: block;
      background-size: cover;
    }

    &._list {
      &:before {
        background-image: inline('icon-list.svg');
        height: 14px;
        width: 14px;
      }
    }

    &._chart {
      &:before {
        background-image: inline('icon-chart.svg');
        height: 18px;
        width: 21px;
      }
    }
  }

  &__title {
    @include font-family-sans-alt;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 11px;
  }

  &__text {
  }

  @include mq-desktop-small {
    margin-bottom: 70px;

    &__title {
      font-size: 28px;
    }

    &__text {
      font-size: 18px;
      max-width: 300px;
    }
  }

  @include mq-tablet {
    padding-left: 64px;
    margin-bottom: 60px;

    &__icon {
      left: 0;
      transform: translateX(0);
    }
  }

  @include mq-mobile {
    margin-bottom: 45px;
    padding-left: 60px;

    &__title {
      font-size: 24px;
    }
  }
}
