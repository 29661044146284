.text-section {
  &__title {
    @include font-family-sans-alt;
    font-size: 36px;
    margin-bottom: 20px;
  }

  &__paragraph {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include mq-desktop-small {
    &__title {
      font-size: 34px;
    }
  }

  @include mq-mobile {
    &__title {
      font-size: 30px;
    }
  }
}
