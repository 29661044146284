@mixin b {
	display: block;
	content: " ";
	position: absolute; }

@mixin hide-input {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
	-webkit-appearance: button; }

@mixin hide-input-file {
	@include hide-input;
	font-size: 100px;
	left: auto;
	right: 0;
	width: auto;
	height: auto; }

// Grid
@mixin cols($cols, $max-cols) {
	width: (100%/$max-cols*$cols); }

@mixin offset($offset-cols, $max-cols) {
	@if $offset-cols == 0 {
		margin-left: 0; }
	@else {
		margin-left: (100%/$max-cols*$offset-cols); } }

@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; }

@mixin letterSpacing($interval) {
	letter-spacing: unquote(($interval / 1000) + 'em'); }

@mixin cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%; }

@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
		@content; } }

@mixin box {
	box-sizing: border-box; }

@mixin ti {
	overflow: hidden;
	text-indent: -999em; }

@mixin placeholder {
	&:-ms-input-placeholder {
		@content; }
	&:-moz-placeholder {
		@content; }
	&::-moz-placeholder {
		@content; }
	&::-webkit-input-placeholder {
		@content; }
	&::placeholder {
		@content; } }

@mixin unselectable {
	user-select: none; }

@mixin prevent-text {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto; }

@mixin gpu {
	@include transform(translate3d(0, 0, 0)); }

@mixin smooth-triangle {
	transform: rotate(0.05deg); }

@mixin scrollbar {
	& ::-moz-scrollbar {
		@content; }
	& ::-webkit-scrollbar {
		@content; }
	& ::scrollbar {
		@content; } }

@mixin scrollbar-track {
	& ::-moz-scrollbar-track {
		@content; }
	& ::-webkit-scrollbar-track {
		@content; }
	& ::scrollbar-track {
		@content; } }

@mixin scrollbar-thumb {
	& ::-moz-scrollbar-thumb {
		@content; }
	& ::-webkit-scrollbar-thumb {
		@content; }
	& ::scrollbar-thumb {
		@content; } }

@mixin scrollbar-track-piece {
	& ::-moz-scrollbar-track-piece {
		@content; }
	& ::-webkit-scrollbar-track-piece {
		@content; }
	& ::scrollbar-track-piece {
		@content; } }

@mixin arr($width, $height, $bg, $direction) {
	width: 0px;
	height: 0px;
	border-style: solid;
	@if $direction == t {
		border-width: 0 $width / 2 + px $height + px $width / 2 + px;
		border-color: transparent transparent $bg transparent; }
	@if $direction == r {
		border-width: $height / 2 + px 0 $height / 2 + px $width + px;
		border-color: transparent transparent transparent $bg; }
	@if $direction == b {
		border-width: $height + px $width / 2 + px 0 $width / 2 + px;
		border-color: $bg transparent transparent transparent; }
	@if $direction == l {
		border-width: $height / 2 + px $width + px $height / 2 + px 0;
		border-color: transparent $bg transparent transparent; }
	@if $direction == tl {
		border-width: $height + px $width + px 0 0;
		border-color: $bg transparent transparent transparent; }
	@if $direction == tr {
		border-width: 0 $width + px $height + px 0;
		border-color: transparent $bg transparent transparent; }
	@if $direction == br {
		border-width: 0 0 $height + px $width + px;
		border-color: transparent transparent $bg transparent; }
	@if $direction == bl {
		border-width: $width + px 0 0 $height + px;
		border-color: transparent transparent transparent $bg; } }

// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin br($radius) {
	border-radius: $radius; }

// Retina Backgrounds for Compass
// by:              Gaya Kessler
// last handleScroll:     03/11/14
// url: https://github.com/pixelmord/Retina-Sprites-for-Compass
//
// Usage:
// 1. create background image for pixel ratio 1 screens and put it somewhere in your images folder.
// 2. create background image for pixel ratio 2 screens and put it somewhere in your images folder.
// 3. include the background-image property in your Sass/Scss using: '@include background-retina(<ratio-1-imagefile>, <ratio-2-imagefile>)'

@mixin background-retina($normal, $retina, $contain: false) {
	background-image: image-url($normal);
	@media (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 3/2), (min--moz-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 144dppx) {
		background-image: image-url($retina);
		@if $contain == true {
			background-size: contain; }
		@else {
			background-size: (image-width($retina) / 2) (image-height($retina) / 2); } } }

@mixin vertical-align($parent_container) {
	vertical-align: middle;
	display: inline-block;
	white-space: normal;
	font-size: $default-font-size;
	@at-root #{$parent_container} {
		white-space: nowrap;
		font-size: 0;
		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			width: 0; } } }

@mixin loading {
	@at-root html._loading #{&} {
		@content; } }

@mixin mac {
	@at-root html._mac #{&} {
		@content; } }

@mixin windows {
	@at-root html._win #{&} {
		@content; } }

@mixin desktop {
	@at-root html._desktop #{&} {
		@content; } }

@mixin mobile {
	@at-root html._mobile #{&} {
		@content; } }

@mixin tablet {
	@at-root html._tablet #{&} {
		@content; } }

@mixin phone {
	@at-root html._phone #{&} {
		@content; } }

@mixin font-family-sans {
  font-family: ProximaNova, Arial, sans-serif; }

@mixin font-family-sans-alt {
  font-family: Gilmer, Arial, sans-serif;
  letter-spacing: -0.02em; }

@mixin font-family-condensed {
  font-family: ProximaNovaCond, "Courier New", Verdana; }

@mixin animated {
	@extend .transition-active;
	&:hover {
		@extend .transition-hover; } }

@mixin button-reset {
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;

  &:focus {
    outline: none; } }

@mixin hover-default {
  @include animated;
  &:hover {
    opacity: 0.8; }
  &:active {
    opacity: 0.6; } }

@mixin pattern-background {
  background: $color-pattern url('/media/img/pattern-background.jpg') no-repeat center top;
  background-size: cover; }

@mixin input-reset {
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent; }

@mixin grid-col-padding {
  padding-left: percents($grid-col-width);

  @include mq-mobile {
    padding-left: 0; } }
