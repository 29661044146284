.page-home {
  &__section-logos {
    padding-top: 120px;
    padding-bottom: 160px;
  }

  @include mq-desktop-small {
    &__section-logos {
      padding-top: 120px;
      padding-bottom: 140px;
    }
  }

  @include mq-tablet {
    &__section-logos {
      padding-bottom: 120px;
    }
  }

  @include mq-mobile {
    &__section-logos {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
}

.home-intro {
  @include pattern-background;
  height: 808px;

  &__container {
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    width: percents(580px);
    padding-top: 225px;
  }

  &__title {
    @include font-family-sans-alt;
    letter-spacing: -0.03em;
    font-size: 65px;
    line-height: 1.3;
    margin-bottom: 28px;
    max-width: 570px;
    overflow: visible;

    .clip-element {
      overflow: visible;
      margin: -0.2em 0;
    }
  }

  &__text {
    color: $color-grey;
    line-height: 1.55;
    margin-bottom: 34px;
    width: 100%;
  }

  &__button {
    padding-left: 22px;
    padding-right: 18px;
  }

  &__video-area {
    position: absolute;
    top: 190px;
    right: -253px;
  }

  @include max-screen(1240px) {
    &__video-area {
      right: -300px;
    }
  }

  @include mq-desktop-small {
    height: 100vh;
    min-height: 700px;
    max-height: 768px;
    padding-bottom: 120px;

    &__title {
      font-size: 52px;
    }

    &__text {
      max-width: 440px;
    }

    &__video-area {
      right: -200px;
    }
  }

  @include mq-tablet {
    height: auto;
    min-height: 0;
    max-height: none;

    &__content {
      width: auto;
      padding-top: 140px;
      margin-bottom: 40px;
    }

    &__title {
      font-size: 45px;
      max-width: 460px;
    }

    &__video-area {
      position: relative;
      top: auto;
      right: auto;
      left: 14px;
    }
  }

  @include mq-mobile {
    padding-bottom: 100px;

    &__content {
      padding-top: 120px;
      width: auto;
      max-width: 400px;
    }

    &__title {
      font-size: 36px;
    }
  }
}

.key-features {
  padding-top: 92px;
  padding-bottom: 134px;

  &__header {
    display: flex;
    margin-bottom: 87px;
  }

  &__title {
    color: $color-dark;
    max-width: 500px;
    line-height: 1.3;
  }

  &__text {
    padding-top: 6px;
    max-width: 500px;
    font-size: 22px;
    font-weight: 500;
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  @include mq-tablet {
    &__text {
      max-width: 320px;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__header {
      display: block;
      margin-bottom: 50px;
    }

    &__title {
      margin-bottom: 30px;
      max-width: none;
    }

    &__text {
      padding-top: 0;
      max-width: none;
    }

    &__items {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.key-features-item {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 10px 50px rgba(#000, 0.05);
  width: percents(360px);
  height: 414px;
  padding: 41px 36px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 29px;
    transform: translateX(-50%);
    width: 97px;
    height: 4px;
    background-color: rgba(#3f3f3f, 0.1);
    border-radius: 2px;
  }

  &__image-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 162px;
    border-radius: 50%;
    margin-bottom: 27px;
    background-color: #f9f9fa;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    font-weight: 700;
    margin-bottom: 17px;
    color: $color-dark;
    font-size: 20px;
  }

  &__text {
    position: relative;
    color: $color-dark-grey;
    font-size: 18px;
    line-height: 1.5;
    padding-left: 26px;
    font-weight: 500;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      display: block;
      width: 18px;
      height: 14px;
      background-image: inline('tick.svg');
      background-size: cover;
    }
  }

  &:first-of-type {
    .key-features-item__image {
      width: 77px;
    }
  }

  &:nth-of-type(2) {
    .key-features-item__image {
      width: 61px;
    }
  }

  &:last-of-type {
    .key-features-item__image {
      width: 52px;
    }
  }

  @include mq-desktop-small {
    $multiplier: 0.7;
    padding: 32px 26px;

    &__image-circle {
      width: 130px;
      height: 130px;
      margin-bottom: 20px;
    }

    &:first-of-type {
      .key-features-item__image {
        width: 116px * $multiplier;
      }
    }

    &:nth-of-type(2) {
      .key-features-item__image {
        width: 103px * $multiplier;
      }
    }

    &:last-of-type {
      .key-features-item__image {
        width: 87px * $multiplier;
      }
    }
  }

  @include mq-tablet {
    $multiplier: 0.6;
    height: 355px;
    padding: 28px 24px;

    &__image-circle {
      width: 110px;
      height: 110px;
    }

    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 16px;
    }

    &:first-of-type {
      .key-features-item__image {
        width: 116px * $multiplier;
      }
    }

    &:nth-of-type(2) {
      .key-features-item__image {
        width: 103px * $multiplier;
      }
    }

    &:last-of-type {
      .key-features-item__image {
        width: 87px * $multiplier;
      }
    }
  }

  @include mq-mobile {
    margin-bottom: 50px;
    width: 280px;
    height: auto;
    padding: 30px 30px 70px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.competitors-feature {
  position: relative;
  z-index: 1;

  &__container {
    display: flex;
    position: relative;
    padding-top: 143px;
    padding-bottom: 160px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: vw(-335px);
    width: vw(897px);
    max-width: 811px;

    @include mq-desktop-large {
      left: -335px;
    }
  }

  &__title {
    margin-bottom: 27px;
    max-width: 570px;
  }

  &__text {
    max-width: 475px;
    line-height: 1.6;
  }

  @include mq-tablet {
    &__container {
      padding-top: 40px;
      padding-bottom: 80px;
    }

    &__image {
      left: vw(-345px);
    }
  }

  @include mq-mobile {
    &__container {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    &__title {
    }

    &__image {
      position: relative;
      top: auto;
      transform: translateX(-20%);
      margin-bottom: 22px;
      width: 120%;
    }
  }
}

.section-letters {
  position: relative;
  padding-top: 151px;
  padding-bottom: 167px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1015px;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 250, 0) 0%,
      #f9f9fa 47.4%
    );
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -96px;
    width: 1px;
    height: 162px;
    background-color: #e0e0e0;
    z-index: 1;
  }

  &__text {
    position: relative;
    margin-bottom: 73px;
    font-size: 28px;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  &__box {
    display: flex;
    justify-content: center;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 80%;
      height: 80px;
      margin-left: -40%;
      background: rgba(5, 5, 5, 0.5);
      filter: blur(100px);
      border-radius: 0 0 8px 8px;
      z-index: 0;
      will-change: transform;
    }

    &-inner {
      @include pattern-background;
      position: relative;
      padding: 36px 15px;
      width: 810px;
      border-top: 3px solid $color-blue;
      border-radius: 0 0 15px 15px;
      overflow: hidden;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, #000 0%, transparent 100%);
      }
    }

    &-content {
      position: relative;
      color: #fff;
      font-size: 20px;

      p {
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include mq-desktop-small {
    padding-top: 60px;

    &__text {
      font-size: 24px;
      max-width: 768px;
    }
  }

  @include mq-tablet {
    padding-bottom: 100px;

    &:after {
      height: 100px;
    }

    &__text {
      font-size: 20px;
      margin-bottom: 50px;
    }

    &__box {
      &-inner {
        border-radius: 0 0 8px 8px;
      }

      &-content {
        font-size: 18px;
      }
    }
  }

  @include mq-mobile {
    padding-top: 40px;
    padding-bottom: 80px;

    &__text {
      margin-bottom: 20px;
      font-size: 18px;
    }

    &__box {
      &-content {
        font-size: 16px;
      }
    }
  }
}

.section-products {
  padding-top: 92px;
  padding-bottom: 200px;

  &__grid-col {
    width: 50%;
  }

  &__header {
    display: flex;
    margin-bottom: 93px;

    &-text {
      max-width: 470px;
      padding-top: 9px;
    }
  }

  &__title {
    max-width: 475px;
  }

  &__items {
    display: flex;
  }

  @include mq-desktop-small {
    &__title {
      max-width: 400px;
    }

    &__header-text {
      max-width: 340px;
    }
  }

  @include mq-tablet {
    padding-bottom: 140px;

    &__header {
      margin-bottom: 70px;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__header {
      display: block;
      margin-bottom: 60px;

      &-text {
        padding-top: 0;
      }
    }

    &__title {
      margin-bottom: 30px;
    }

    &__items {
      display: block;
    }

    &__grid-col {
      width: 100%;

      &._bottom {
        margin-bottom: 55px;
      }
    }
  }
}

.section-products-item {
  $width: 497px;
  width: vw($width);
  max-width: $width;

  &__bar {
    display: flex;
    justify-content: space-between;
    padding-left: 26px;
    padding-right: 35px;
    align-items: center;
    color: #fff;
    background-color: #050505;
    height: 75px;
    border-radius: 8px;
    margin-bottom: 30px;

    &-title {
      @include font-family-sans-alt;
      font-size: 24px;
      font-weight: 700;
    }

    &-logo {
      width: 111px;
      height: 13px;
      fill: #fff;
    }

    &._blue {
      background-color: $color-blue;
    }
  }

  &__text {
    margin-bottom: 48px;
  }

  @include mq-desktop-small {
    &__bar {
      height: 66px;
      padding-left: 20px;
      padding-right: 30px;

      &-title {
        font-size: 22px;
      }

      &-logo {
        width: 100px;
      }
    }

    &__text {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  @include mq-tablet {
    &__bar {
      height: 60px;

      &-title {
        font-size: 18px;
      }
    }
  }

  @include mq-mobile {
    width: auto;
    max-width: 320px;

    &__bar {
      margin-bottom: 27px;
      height: 52px;
      border-radius: 6px;
    }

    &__text {
      margin-bottom: 30px;
    }
  }
}

.section-print-postage {
  padding-top: 180px;
  padding-bottom: 278px;
  background-image: linear-gradient(to bottom, #f9f9fa, #fff);

  &__container {
    position: relative;
  }

  &__content {
    max-width: percents(500px);
  }

  &__subtitle {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 16px;
    max-width: 420px;
    font-size: 40px;
  }

  &__image {
    position: absolute;
    right: 110px;
    top: -30px;
  }

  @include mq-desktop-small {
    padding-bottom: 200px;

    &__content {
      max-width: 440px;
    }
  }

  @include mq-tablet {
    padding-bottom: 120px;

    &__title {
      max-width: 400px;
      font-size: 36px;
    }

    &__text {
      max-width: 320px;
    }

    &__image {
      max-width: 300px;
      width: 100%;
      top: 30px;
      right: 40px;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__title {
      font-size: 32px;
      margin-bottom: 30px;
    }

    &__text {
      max-width: 440px;
    }

    &__image {
      position: static;
      margin-bottom: 40px;
    }
  }
}
