.back {
  @include button-reset;
  @include hover-default;
  color: $color-dark-grey;
  font-size: 14px;
  display: flex;
  align-items: center;

  &__icon {
    transform: scale(-1);
    fill: $color-dark-grey;
    width: 6px;
    height: 10px;
    margin-right: 7px;
  }
}
