.book-screen {
  background-color: #fff;
  z-index: 10;

  &__inner {
    position: relative;
    min-height: 100%;
  }

  &__container {
  }

  &__content {
    width: 448px;
    padding-top: 64px;
    padding-bottom: 60px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
  }

  &__logo-link {
    font-size: 0;
  }

  &__logo {
    fill: #1f1f1f;
  }

  &__title {
    @include font-family-sans-alt;
    font-size: 36px;
    margin-bottom: 12px;
    letter-spacing: -0.03em;
  }

  &__text {
    margin-bottom: 36px;
    font-size: 14px;
    max-width: 340px;
  }

  &__media-area {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: vw(700px);
    background-color: #070707;
    background-image: url('/media/img/book-demo-map@2x.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @include max-screen(1400px) {
    &__content {
      width: 500px;
    }
  }

  @include mq-desktop-small {
    &__content {
      width: 48%;
    }
  }

  @include mq-mobile {
    &__title {
      font-size: 30px;
    }

    &__content {
      width: auto;
    }

    &__media-area {
      display: none;
    }
  }
}
