.section-db-features {
  padding-top: 70px;
  padding-bottom: 110px;

  &__title {
    margin-bottom: 112px;
    text-align: center;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;

    .clip-element {
      line-height: 1.5;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: -34px;
  }

  &__items-col {
    width: 25%;
    padding: 0 17px;
    margin-bottom: 34px;

    &:nth-of-type(1n) {
      .section-db-features__item {
        &:after {
          background-image: inline('lines1.svg');
          width: 139px;
        }
      }
    }

    &:nth-of-type(2n) {
      .section-db-features__item {
        &:after {
          background-image: inline('lines2.svg');
          width: 108px;
        }
      }
    }

    &:nth-of-type(3n) {
      .section-db-features__item {
        &:after {
          background-image: inline('lines3.svg');
          width: 114px;
        }
      }
    }

    &:nth-of-type(4n) {
      .section-db-features__item {
        &:after {
          background-image: inline('lines4.svg');
          width: 144px;
        }
      }
    }
  }

  &__item {
    @include font-family-sans-alt;
    position: relative;
    font-size: 26px;
    font-weight: 700;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
    padding: 27px 29px 35px;
    height: 100%;
    min-height: 257px;
    line-height: 1.5;

    &:before {
      content: '';
      display: block;
      width: 34px;
      height: 34px;
      background-image: inline('tick-circle-alt2.svg');
      background-size: cover;
      margin-bottom: 28px;
    }

    //&:after {
    //  content: '';
    //  display: block;
    //  position: absolute;
    //  left: 29px;
    //  bottom: 23px;
    //  background-size: cover;
    //  height: 23px;
    //}
  }

  &__bottom-text {
    margin-top: 105px;
    font-size: 16px;
    text-align: center;
  }

  @include mq-desktop-small {
    &__item {
      font-size: 24px;
      min-height: 0;

      &:before {
        margin-bottom: 18px;
      }
    }
  }

  @include mq-tablet {
    &__title {
      margin-bottom: 80px;
    }

    &__items-col {
      width: 50%;
    }

    &__bottom-text {
      margin-top: 80px;
    }
  }

  @include mq-mobile {
    &__title {
      margin-bottom: 60px;
    }

    &__items {
      justify-content: center;
    }

    &__items-col {
      width: 100%;
      max-width: 280px;
    }

    &__bottom-text {
      margin-top: 60px;
    }
  }
}
