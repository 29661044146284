.testimonials {
  margin-top: -123px;

  &__box {
    position: relative;
    width: 994px;
    max-width: 85%;
    height: 402px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    overflow: hidden;
    will-change: transform;
    box-shadow: 0 10px 50px rgba(#000, 0.05);
  }

  &__slides {
    display: flex;
    height: 100%;
    will-change: transform;
  }

  &__next {
    @include button-reset;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 89px;
    height: 100%;
    background-color: $color-dark-blue;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:active {
      background-color: lighten($color-dark-blue, 10);
    }

    &-icon {
      width: 14px;
      height: 22px;
      fill: #fff;
    }
  }

  @include mq-desktop-small {
    &__box {
      height: 430px;
    }
  }

  @include mq-tablet {
    &__box {
      max-width: 90%;
      height: 460px;
    }

    &__next {
      width: 80px;
    }
  }

  @include mq-mobile {
    &__box {
      height: auto;
    }

    &__next {
      display: flex;
      align-items: center;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 67px;

      &:before {
        content: 'Next Story';
        color: #fff;
        font-size: 17px;
        margin-right: 10px;
      }

      &-icon {
        position: relative;
        width: 8px;
        height: 15px;
      }
    }
  }
}

.testimonials-slide {
  display: flex;
  flex: 1 0 auto;

  &__photo-area {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.9%;
    background-color: #fff;
    padding: 0 35px;
  }

  &__photo {
    max-width: 100%;
  }

  &__content-area {
    $width: 994px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 66.1%;
    background-color: $color-blue;
    color: #fff;
    padding: percents(46px, $width) 110px percents(35px, $width)
      percents(65px, $width);
  }

  &__title {
    @include font-family-sans-alt;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 30px;
  }

  &__text {
    font-size: 15px;
    margin-bottom: 20px;
    color: inherit;
    line-height: 1.65;
    max-width: 430px;
  }

  &._small-font-size {
    .testimonials-slide {
      &__text {
        font-size: 12px;
      }
    }
  }

  @include mq-desktop-small {
    &__photo-area {
      width: 30%;
    }

    &__content-area {
      width: 70%;
      padding-right: 130px;
    }
  }

  @include mq-tablet {
    &__photo-area {
      width: 30%;
    }

    &__photo {
      max-width: 95%;
    }

    &__content-area {
      width: 70%;
      padding-right: 110px;
    }

    &__title {
      font-size: 32px;
    }

    &__text {
      font-size: 15px;
    }
  }

  @include mq-mobile {
    flex-direction: column;

    &__photo-area,
    &__content-area {
      width: 100%;
    }

    &__photo-area {
      height: 300px;
      flex-shrink: 0;
    }

    &__content-area {
      padding: 30px 30px 100px;
      height: 100%;
    }
  }
}

.testimonials-author {
  &__name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  &__additional {
    font-size: 14px;
    color: inherit;
  }
}
