.title {
  @include font-family-sans-alt;
  position: relative;
  font-weight: 700;
  font-size: 48px;

  .clip-element {
    margin: -0.0525em 0;
    line-height: 1.25;
  }

  &._decorative-shape {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: -60px;
      width: 140px;
      height: 0;
      border-top: 65px solid #f9f9fa;
      border-left: 40px solid transparent;
      z-index: -1;
    }
  }

  &._big {
    font-size: 70px;
  }

  &._small {
    font-size: 40px;
  }

  @include mq-desktop-small {
    font-size: 37px;

    &._big {
      font-size: 56px;
    }

    &._small {
      font-size: 37px;
    }
  }

  @include mq-tablet {
    font-size: 32px;

    &._decorative-shape {
      &:before {
        top: -5px;
        left: -40px;
        width: 120px;
        border-top-width: 50px;
      }
    }

    &._big {
      font-size: 50px;
    }

    &._small {
      font-size: 32px;
    }
  }

  @include mq-mobile {
    font-size: 30px;

    &._decorative-shape {
      &:before {
        display: none;
      }
    }

    &._big {
      font-size: 40px;
    }

    &._small {
      font-size: 30px;
    }
  }

  @include mq-mobile-xs {
    font-size: 27px;

    &._small {
      font-size: 27px;
    }
  }
}
