.book-demo-form {
  &__inputs {
    margin-bottom: 38px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 0 -14px 26px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__col {
    flex: 1 0 auto;
    width: 50%;
    padding: 0 14px;
  }

  &__checkbox {
    margin-bottom: 9px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__submit {
    width: 100%;
    margin-top: 38px;
  }

  @include mq-mobile {
    &__row {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &__col {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
