.cta-block {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;

  &__box {
    position: relative;
    padding: 74px 60px 65px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    background-color: #4b4ccf;
    background-image: url('/media/img/pattern-wave.png');
    background-size: cover;
    color: #fff;
    text-align: center;
  }

  &__title {
    margin-bottom: 18px;
    font-size: 60px;
  }

  &__text {
    margin-bottom: 55px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    &:first-of-type {
      margin-right: 32px;
    }
  }

  &._dark {
    background-color: $color-dark-blue;
  }

  &._before-footer {
    padding-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      background-color: $color-dark-blue;
      height: 50%;
      width: 100%;
    }
  }

  @include mq-tablet {
    padding-left: 0;
    padding-right: 0;

    &__title {
      font-size: 56px;
    }
  }

  @include mq-mobile {
    &__box {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__title {
      font-size: 48px;
    }

    &__button {
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }

  @include mq-mobile-xs {
    &__buttons {
      flex-direction: column;
      align-items: center;
    }

    &__button {
      &:first-of-type {
        margin-bottom: 30px;
        margin-right: 0;
      }
    }
  }
}
