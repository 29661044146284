.success {
  margin-bottom: 18px;

  &__item {
    position: relative;
    color: $color-green;
    margin-bottom: 6px;
    padding-left: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-green;
    }
  }
}
