.text-with-icon {
  position: relative;
  padding-left: 55px;
  max-width: 320px;

  &:before {
    @include cover;
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    display: block;
    width: 34px;
    height: 34px;
    background-image: inline('tick-circle.svg');
  }

  &__title {
    @include font-family-sans-alt;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 7px;
  }

  &__text {
    @extend .text;
    font-size: 18px;
    color: $color-grey;
    padding-left: 2px;
  }

  &._large {
    padding-left: 70px;

    &:before {
      top: 1px;
      width: 40px;
      height: 40px;
    }

    .text-with-icon {
      &__title {
        font-size: 30px;
      }

      &__text {
        font-size: 20px;
      }
    }
  }

  @include mq-tablet {
    width: 290px;
    max-width: none;
  }

  @include mq-mobile {
    width: 260px;

    &__title {
      font-size: 22px;
    }

    &._large {
      padding-left: 55px;

      &:before {
        width: 34px;
        height: 34px;
      }

      .text-with-icon {
        &__title {
          font-size: 22px;
        }

        &__text {
          font-size: 18px;
        }
      }
    }
  }
}
