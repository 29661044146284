.link-arrow {
  $color: #3f3f3f;
  @include button-reset;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: $color;
  transition: color $default-transition-duration $default-transition-function;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: rgba($color, 0.9);
    transition: none;

    .link-arrow__icon {
      transform: translateX(1px);
      transition: none;
    }
  }

  &:active {
    color: rgba($color, 0.8);

    .link-arrow__icon {
      transform: translateX(2px);
      transition: none;
    }
  }

  &__icon {
    width: 6px;
    height: 10px;
    margin-left: 10px;
    fill: $color-blue;
    transition: transform $default-transition-duration
      $default-transition-function;
  }

  @include mq-tablet {
    font-size: 16px;
  }
}
