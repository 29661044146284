.logo-carousel {
  pointer-events: none;

  &:after {
    content: '';
    display: block;
    height: 1px;
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    background-color: #c8d8e0;
  }

  &__container {
    max-width: 1240px;
  }

  &__swiper-container {
    position: relative;
    height: 148px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    user-select: none;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 300px;
      z-index: 1;
    }

    &:before {
      background-image: linear-gradient(to right, #fff, rgba(#fff, 0));
      left: 0;
    }

    &:after {
      background-image: linear-gradient(to left, #fff, rgba(#fff, 0));
      right: 0;
    }
  }

  &__track {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    align-items: center;
    height: 100%;
    padding: 30px;
    transition-timing-function: linear !important;
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 1 0 auto;
    padding: 0 15px;
    pointer-events: none;

    &-image {
      display: block;
      max-width: 100%;
      max-height: 100%;
      opacity: 0.8;
    }
  }

  @include mq-desktop-small {
    &__swiper-container {
      height: 110px;
    }
  }

  @include mq-tablet {
    &__swiper-container {
      &:before,
      &:after {
        width: 200px;
      }
    }
  }

  @include mq-mobile {
    &__swiper-container {
      height: 80px;

      &:before,
      &:after {
        width: 80px;
      }
    }
  }
}
