.brand-protection {
  padding-top: 170px;
  padding-bottom: 112px;

  &__subtitle {
    text-align: center;
    margin-bottom: 18px;
  }

  &__title {
    @include font-family-sans-alt;
    margin-bottom: 114px;
    font-size: 60px;
    text-align: center;
  }

  &__items {
    display: flex;
    justify-content: center;
  }

  &__text {
    font-size: 28px;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 108px;
  }

  @include mq-tablet {
    &__title {
      font-size: 50px;
      margin-bottom: 80px;
    }

    &__text {
      margin-top: 80px;
      font-size: 22px;
    }
  }

  @include mq-mobile {
    padding-top: 80px;
    padding-bottom: 80px;

    &__title {
      font-size: 36px;
      margin-bottom: 50px;
    }

    &__text {
      margin-top: 55px;
      font-size: 20px;
    }

    &__items {
      flex-direction: column;
      align-items: center;
    }
  }
}

.brand-protection-item {
  $overlap-size: 20px;
  position: relative;
  text-align: center;
  width: 272px;
  height: 272px;
  border-radius: 50%;
  background-color: #eaedef;
  padding-top: 79px;
  user-select: none;
  overflow: hidden;
  will-change: transform;
  margin: 0 10px;
  flex: 1 0 auto;
  font-weight: 500;

  &:nth-of-type(2) {
    z-index: 1;

    .brand-protection-item__overlapping {
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid #fff;
      will-change: transform;
    }
  }

  &__tick {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #eaedef;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 17px;

    &-icon {
      fill: #a0abb1;
      width: 13px;
      height: 10px;
    }

    &._dark {
      background-color: #3f3f3f;

      .brand-protection-item__tick-icon {
        fill: #a0abb1;
      }
    }
  }

  &__title {
    font-size: 20px;
    margin-bottom: 26px;
  }

  &__lines {
    &:before,
    &:after {
      content: '';
      display: block;
      height: 4px;
      border-radius: 2px;
      background-color: rgba(#3f3f3f, 0.1);
      margin-left: auto;
      margin-right: auto;
    }

    &:before {
      width: 97px;
      margin-bottom: 7px;
    }

    &:after {
      width: 65px;
    }

    &._bright {
      &:before,
      &:after {
        background-color: $color-dark-grey;
      }
    }
  }

  &._blue {
    background-color: $color-blue;
    color: #fff;
  }

  &._white {
    background-color: #fff;
    border: 2px solid $color-blue;
  }

  &._dark {
    background-color: #191c20;
    color: #fff;
  }

  @include mq-desktop-small {
    $overlap-size: 10px;
    width: 234px;
    height: 234px;
    padding-top: 60px;
    margin: 0;

    &__title {
      font-size: 18px;
    }
  }

  @include mq-tablet {
    width: 190px;
    height: 190px;

    &__tick {
      margin-bottom: 10px;
      width: 30px;
      height: 30px;
    }

    &__title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  @include mq-mobile {
    width: 210px;
    height: 210px;
    margin: 0 0 -20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(2) {
      &:before {
        display: none;
      }
    }
  }
}
