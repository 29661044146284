.text-big {
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;

  @include mq-tablet {
    font-size: 22px;
  }

  @include mq-mobile {
    font-size: 20px;
  }
}
