.text {
  color: $color-dark-grey;
  font-size: 20px;
  line-height: 1.5;

  @include mq-tablet {
    font-size: 18px;
  }

  &._on-dark {
    color: $color-grey;
  }
}
