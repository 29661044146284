.privacy-sections {
  padding-bottom: 90px;

  &__header {
    margin-bottom: 84px;
  }

  &__title {
    margin-bottom: 18px;
  }

  &__date {
    color: $color-dark-grey;
    padding-left: 0.15em;

    &-highlight {
      color: #000;
      font-weight: 700;
    }
  }

  &__content {
    position: relative;
    @include grid-col-padding;
  }

  &__navigation-container {
    position: absolute;
    left: 0;
    width: 300px;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-item {
      @include animated;
      display: flex;
      align-items: center;
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 28px;
      color: #c4c4c4;
      cursor: pointer;
      user-select: none;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:hover {
        color: darken(#c4c4c4, 10);
      }

      &:active {
        color: darken(#c4c4c4, 15);
      }

      &._active {
        color: $color-dark;
      }
    }

    &-item-icon {
      width: 5px;
      height: 8px;
      fill: $color-blue;
      margin-right: 22px;
    }

    &[data-hidden] {
      visibility: hidden;
      opacity: 0;
      position: absolute;
    }
  }

  &__section {
    margin-bottom: 80px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include mq-desktop-small {
    &__header {
      margin-bottom: 66px;
    }
  }

  @include mq-tablet {
    &__header {
      margin-bottom: 50px;
    }

    &__navigation-container {
      display: none;
    }

    &__content {
      padding-left: 0;
      max-width: 90%;
    }
  }

  @include mq-mobile {
    &__header {
      margin-bottom: 40px;
    }

    &__section {
      margin-bottom: 50px;
    }
  }
}
