$default-font-size: 16px;

$default-transition-duration: 0.3s;
$default-transition-function: ease-out;

$breakpoint-lg: 1179px;
$breakpoint-md: 991px;
$breakpoint-sm: 767px;
$breakpoint-xs: 374px;

$indent-col-lg: 30px;
$indent-col-md: 30px;
$indent-col-sm: 20px;
$indent-col-xs: 16px;

$cols-total: 12;
$offset-side: 35px;
$offset-side-md: 30px;
$offset-side-sm: 20px;

$mockup-width: 1440px;
$container-width: 1170px;

$grid-col-width: 590px;
