.text-switcher {
  position: relative;
  height: 280px;

  &__buttons {
    position: absolute;
    right: 68%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  &__slides-container {
    @include grid-col-padding;
  }

  &__slide-circle {
    margin-bottom: 19px;
    will-change: transform;
  }

  @include mq-desktop-small {
    &__buttons {
      right: 64%;
    }
  }

  @include mq-tablet {
    &__buttons {
      right: 62%;
    }
  }

  @include mq-mobile {
    height: auto;

    &__buttons {
      position: static;
      align-items: flex-start;
      margin-bottom: 40px;
    }
  }

  &__slides-container {
    height: 270px;
  }
}

$button-transition: transform 0.35s ease-out;

.text-switcher-button {
  @include hover-default;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 26px;
  font-weight: 700;
  color: $color-dark-grey;
  margin-bottom: 35px;
  cursor: pointer;
  transition: $button-transition;
  transform-origin: right center;
  will-change: transform;
  user-select: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__circle {
    margin-right: 12px;
    transition: opacity 0.15s ease-out;
    flex: 1 0 auto;
  }

  &._active {
    color: $color-dark;
    transition: $button-transition;
    transform: scale(1.38);

    .text-switcher-button {
      &__circle {
        opacity: 0;
      }
    }
  }

  @include mq-desktop-small {
    font-size: 24px;
  }

  @include mq-tablet {
    font-size: 18px;
    max-width: 240px;
  }

  @include mq-mobile {
    justify-content: flex-start;
    flex-direction: row-reverse;
    margin-bottom: 15px;
    transform-origin: left center;
    max-width: none;

    &__circle {
      margin-left: 10px;
    }
  }
}

.text-switcher-slide {
  position: absolute;

  &__title,
  &__text {
    visibility: hidden;
    opacity: 0;
    will-change: transform;
  }

  &__title {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  &__text {
    max-width: 480px;
    font-size: 22px;
  }

  @include mq-desktop-small {
    &__title {
      font-size: 38px;
      margin-bottom: 20px;
    }

    &__text {
      font-size: 20px;
    }
  }

  @include mq-tablet {
    &__title {
      font-size: 32px;
    }
  }

  @include mq-mobile {
    &__circle {
      margin-bottom: 7px;
    }

    &__title {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
}
