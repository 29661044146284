.burger {
  position: relative;
  width: 26px;
  cursor: pointer;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
  }

  &__line {
    height: 1px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
