@font-face { font-family: "Gilmer"; font-weight: 700; src: url("../fonts/Gilmer-Bold.woff2") format("woff2"), url("../fonts/Gilmer-Bold.woff") format("woff"); }

@font-face { font-family: "ProximaNova"; font-weight: 400; src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/ProximaNova-Regular.woff") format("woff"); }

@font-face { font-family: "ProximaNova"; font-weight: 700; src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff"); }

@font-face { font-family: "ProximaNova"; font-weight: 500; src: url("../fonts/ProximaNova-Semibold.woff2") format("woff2"), url("../fonts/ProximaNova-Semibold.woff") format("woff"); }

@font-face { font-family: "ProximaNovaCond"; font-weight: 700; src: url("../fonts/ProximaNovaCond-Bold.woff2") format("woff2"), url("../fonts/ProximaNovaCond-Bold.woff") format("woff"); }

* { padding: 0; margin: 0; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

*, *:before, *:after { box-sizing: border-box; }

html, body { width: 100%; height: 100%; cursor: default; -webkit-font-smoothing: antialiased; }

body { color: #2b2b2b; background: #FFF; font-family: ProximaNova, Arial, sans-serif; }

img, fieldset { border: 0; }

fieldset legend { display: none; }

ul, li { list-style-type: none; }

img { vertical-align: top; }

table { border: 0; border-collapse: collapse; }

table td { padding: 0; vertical-align: top; }

input, select, textarea { box-sizing: border-box; }

input[type=number]::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }

input[type=number] { -moz-appearance: textfield; }

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration { display: none; }

input[type=search] { -webkit-appearance: textfield; }

::selection { background: #33378f; color: #FFFFFF; text-shadow: none; }

::-moz-selection { background: #33378f; color: #FFFFFF; text-shadow: none; }

#__bs_notify__ { padding: 2px !important; font-size: 10px !important; opacity: 0.75; user-select: none; pointer-events: none; }

button { user-select: none; border-radius: 0; }

.wrapper { overflow: hidden; min-height: 100%; }

img { transition: opacity 0.35s ease-out; }

img:not([src]) { opacity: 0; }

.clearfix:after { content: " "; display: block; height: 0; clear: both; visibility: hidden; font-size: 0; }

.invisible-container { position: absolute; top: -9999px; left: -9999px; width: 0; height: 0; overflow: hidden; z-index: -1; }

.no-select { user-select: none; }

.no-pe { pointer-events: none; }

.static-text p { margin-bottom: 1.4em; }

.static-text img { max-width: 100%; }

@keyframes rotating { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.transition-active, .highlight._link, .button, .header__navigation-item-link, .header__additional-link, .mobile-menu__back, .footer__social-link, .back, .input-field__show-password, .link-text, .text-switcher-button, .privacy-sections__navigation-item, .nav-dropdown-item, .section-awards-item__link, .section-news-item__link { transition: color 0.3s ease-out, background 0.3s ease-out, border-color 0.3s ease-out, opacity 0.3s ease-out; }

.transition-hover, .highlight._link:hover, .header__navigation-item-link:hover, .header__additional-link:hover, .mobile-menu__back:hover, .footer__social-link:hover, .back:hover, .input-field__show-password:hover, .link-text:hover, .text-switcher-button:hover, .privacy-sections__navigation-item:hover, .nav-dropdown-item:hover, .section-awards-item__link:hover, .section-news-item__link:hover { transition: none; }

.force3D { transform: translate3d(0, 0, 0); backface-visibility: hidden; }

.color-grey { color: #c0c0cd; }

.highlight { color: #5359ea; }

.highlight._link { text-decoration: none; }

.highlight._link:hover { opacity: 0.8; }

.highlight._link:active { opacity: 0.6; }

.bold { font-weight: 700; }

.grid-col { width: 50.42735%; padding-right: 30px; }

@media only screen and (max-width: 767px) { .grid-col { width: auto; padding-right: 0; } }

@media only screen and (max-width: 767px) { .hidden-xs { display: none !important; } }

.visible-xs { display: none; }

@media only screen and (max-width: 767px) { .visible-xs { display: block; } }

/***=== COMPONENTS ===***/
.page._header-padding { padding-top: 245px; }

@media only screen and (max-width: 991px) { .page._header-padding { padding-top: 200px; } }

@media only screen and (max-width: 767px) { .page._header-padding { padding-top: 155px; } }

.text, .text-with-icon__text, .nav-dropdown-item__text { color: #666; font-size: 20px; line-height: 1.5; }

@media only screen and (max-width: 991px) { .text, .text-with-icon__text, .nav-dropdown-item__text { font-size: 18px; } }

.text._on-dark, ._on-dark.text-with-icon__text, ._on-dark.nav-dropdown-item__text { color: #c0c0cd; }

.title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; position: relative; font-weight: 700; font-size: 48px; }

.title .clip-element { margin: -0.0525em 0; line-height: 1.25; }

.title._decorative-shape:before { content: ''; display: block; position: absolute; top: 3px; left: -60px; width: 140px; height: 0; border-top: 65px solid #f9f9fa; border-left: 40px solid transparent; z-index: -1; }

.title._big { font-size: 70px; }

.title._small { font-size: 40px; }

@media only screen and (max-width: 1179px) { .title { font-size: 37px; }
  .title._big { font-size: 56px; }
  .title._small { font-size: 37px; } }

@media only screen and (max-width: 991px) { .title { font-size: 32px; }
  .title._decorative-shape:before { top: -5px; left: -40px; width: 120px; border-top-width: 50px; }
  .title._big { font-size: 50px; }
  .title._small { font-size: 32px; } }

@media only screen and (max-width: 767px) { .title { font-size: 30px; }
  .title._decorative-shape:before { display: none; }
  .title._big { font-size: 40px; }
  .title._small { font-size: 30px; } }

@media only screen and (max-width: 374px) { .title { font-size: 27px; }
  .title._small { font-size: 27px; } }

.styled-list__item { position: relative; font-size: 22px; margin-bottom: 32px; padding-left: 60px; font-weight: 700; line-height: 1.65; }

.styled-list__item:last-of-type { margin-bottom: 0; }

.styled-list__item:before { content: ''; display: block; position: absolute; left: 0; top: 2px; width: 34px; height: 34px; background-image: inline("tick-circle-alt2.svg"); background-size: cover; }

.styled-list._two-cols { display: flex; flex-wrap: wrap; }

.styled-list._two-cols .styled-list__item { width: 50%; margin-bottom: 20px; padding-right: 10px; }

.styled-list._two-cols .styled-list__item:nth-last-child(2), .styled-list._two-cols .styled-list__item:last-child { margin-bottom: 0; }

.styled-list._bigger-font-size .styled-list__item { font-size: 26px; }

@media only screen and (max-width: 991px) { .styled-list__item { font-size: 20px; }
  .styled-list._two-cols { display: block; }
  .styled-list._two-cols .styled-list__item { width: auto; }
  .styled-list._two-cols .styled-list__item, .styled-list._two-cols .styled-list__item:nth-last-child(2), .styled-list._two-cols .styled-list__item:last-child { margin-bottom: 20px; }
  .styled-list._bigger-font-size .styled-list__item { font-size: 22px; } }

.text-section__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 36px; margin-bottom: 20px; }

.text-section__paragraph { margin-bottom: 25px; }

.text-section__paragraph:last-of-type { margin-bottom: 0; }

@media only screen and (max-width: 1179px) { .text-section__title { font-size: 34px; } }

@media only screen and (max-width: 767px) { .text-section__title { font-size: 30px; } }

.container { width: 100%; max-width: 1240px; margin-left: auto; margin-right: auto; padding-left: 35px; padding-right: 35px; }

.container._relative { position: relative; }

@media only screen and (max-width: 991px) { .container { padding-left: 30px; padding-right: 30px; } }

@media only screen and (max-width: 767px) { .container { padding-left: 20px; padding-right: 20px; } }

.button { border: none; background-color: transparent; border-radius: 0; -webkit-appearance: none; text-decoration: none; user-select: none; cursor: pointer; display: flex; align-items: center; justify-content: center; border: 2px solid rgba(255, 255, 255, 0.3); height: 54px; border-radius: 5px; font-weight: 700; cursor: pointer; background-clip: padding-box; font-size: 16px; color: #fff; letter-spacing: -0.5px; padding: 1px 20px 0; }

.button:focus { outline: none; }

.button:hover { background-color: rgba(255, 255, 255, 0.3); color: rgba(255, 255, 255, 0.8); transition: none; }

.button:active { background-color: rgba(255, 255, 255, 0.25); border-color: rgba(255, 255, 255, 0.25); }

.button:active .button__chevron-right { transform: translateX(1px); transition: none; }

.button__chevron-right { margin-left: 13px; width: 6px; height: 9px; fill: #fff; transition: transform 0.1s ease-out, fill 0.3s ease-out; }

.button._chevron-right { padding-left: 1.28571em; padding-right: 0.92857em; }

.button._large-chevron .button__chevron-right { width: 7px; height: 10px; margin-left: 20px; }

.button._blue-border { border-color: #5359ea; }

.button._blue-border:hover { background-color: #5359ea; color: #fff; }

.button._blue-border:hover .button__chevron-right { fill: #fff; }

.button._blue-border:active { background-color: rgba(83, 89, 234, 0.9); border-color: rgba(83, 89, 234, 0.9); }

.button._white-border { border-color: #fff; }

.button._white-border:hover { background-color: #fff; color: #5359ea; }

.button._white-border:active { background-color: rgba(255, 255, 255, 0.9); border-color: rgba(255, 255, 255, 0.9); }

.button._blue { background-color: #5359ea; border-color: #5359ea; }

.button._blue:hover { color: #fff; background-color: rgba(83, 89, 234, 0.9); border-color: rgba(83, 89, 234, 0.9); }

.button._blue:active { background-color: rgba(83, 89, 234, 0.8); border-color: rgba(83, 89, 234, 0.8); }

.button._white { background-color: #fff; border-color: #fff; color: #5359ea; }

.button._white .button__chevron-right { fill: #5359ea; }

.button._white:hover { background-color: rgba(255, 255, 255, 0.9); border-color: rgba(255, 255, 255, 0.9); }

.button._white:active { background-color: rgba(255, 255, 255, 0.8); border-color: rgba(255, 255, 255, 0.8); }

.button._color-dark { color: #2b2b2b; }

.button._color-dark:hover .button__chevron-right { fill: #fff; transition: transform 0.1s ease-out; }

.button._color-dark .button__chevron-right { fill: #5359ea; }

.button._blue-chevron .button__chevron-right { fill: #5359ea; }

.button._shadow { box-shadow: 0 7px 12px rgba(83, 89, 234, 0.4); }

@media only screen and (max-width: 767px) { .button { height: 46px; font-size: 14px; } }

.header { position: fixed; top: 0; left: 0; width: 100%; color: #fff; height: 144px; user-select: none; z-index: 10; transition: background-color 0.35s ease-out, transform 0.35s ease-out, height 0.35s ease-out; will-change: transform; }

.header__background { position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-image: url("/media/img/pattern-background.jpg"); background-size: 100%; background-position: center top; transition: opacity 0.1s ease-out; will-change: transform; }

.header__container { position: relative; display: flex; align-items: center; height: 100%; justify-content: space-between; z-index: 1; }

.header__logo-link { font-size: 0; }

.header__logo-link._disabled { pointer-events: none; }

.header__logo { transition: fill 0.35s ease-out; }

.header__navigation { display: flex; position: relative; }

.header__navigation-item { position: relative; margin: 0 37px; }

.header__navigation-item._dropdown .header__navigation-item-link:after { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; content: ''; display: block; background-image: inline("chevron-down-white.svg"); width: 9px; height: 6px; margin-left: 9px; }

.header__navigation-item-link { display: flex; align-items: center; color: #fff; text-decoration: none; font-weight: 700; font-size: 17px; cursor: pointer; }

.header__navigation-item-link:hover { opacity: 0.8; }

.header__navigation-item-link:active { opacity: 0.6; }

.header__right-section { display: flex; align-items: center; }

.header__additional-link { color: rgba(192, 192, 205, 0.7); font-size: 14px; margin-right: 46px; font-weight: 700; text-decoration: none; }

.header__additional-link:hover { opacity: 0.8; }

.header__additional-link:active { opacity: 0.6; }

.header__button { text-transform: uppercase; font-size: 14px; height: 43px; }

.header__burger { display: none; }

.header._scrolled { background-color: #fff; color: #2b2b2b; transform: translate3d(0px, -100%, 0px); }

.header._scrolled .header__background { opacity: 0; transition-delay: 0.35s; }

.header._scrolled .header__logo { fill: #000; }

.header._scrolled .header__navigation-item._dropdown .header__navigation-item-link:after { background-image: inline("chevron-down-grey.svg"); }

.header._scrolled .header__navigation-item-link { color: #2b2b2b; }

.header._scrolled .header__additional-link { color: #666; }

.header._scrolled .header__burger .burger__line { background-color: #2b2b2b; }

.header._scrolled._visible { transform: translate3d(0px, 0px, 0px); box-shadow: 0 0 6px rgba(43, 43, 43, 0.15); height: 106px; }

.header._transparent .header__background { display: none; }

@media only screen and (max-width: 1179px) { .header__navigation-link { margin: 0 23px; }
  .header__additional-link { margin-right: 32px; } }

@media only screen and (max-width: 991px) { .header { height: 100px; }
  .header__navigation { left: auto; }
  .header__navigation-link { margin: 0 14px; font-size: 15px; }
  .header__additional-link { margin-right: 22px; }
  .header__button { font-size: 13px; height: 36px; }
  .header__navigation { display: none; }
  .header__right-section { display: none; }
  .header__burger { display: block; }
  .header._scrolled._visible { height: 100px; } }

@media only screen and (max-width: 767px) { .header { height: 80px; }
  .header._scrolled._visible { height: 80px; } }

.logo-carousel { pointer-events: none; }

.logo-carousel:after { content: ''; display: block; height: 1px; max-width: 1170px; margin-left: auto; margin-right: auto; background-color: #c8d8e0; }

.logo-carousel__container { max-width: 1240px; }

.logo-carousel__swiper-container { position: relative; height: 148px; overflow: hidden; opacity: 0; visibility: hidden; user-select: none; }

.logo-carousel__swiper-container:before, .logo-carousel__swiper-container:after { content: ''; display: block; position: absolute; top: 0; height: 100%; width: 300px; z-index: 1; }

.logo-carousel__swiper-container:before { background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)); left: 0; }

.logo-carousel__swiper-container:after { background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0)); right: 0; }

.logo-carousel__track { display: flex; flex-wrap: nowrap; will-change: transform; align-items: center; height: 100%; padding: 30px; transition-timing-function: linear !important; }

.logo-carousel__slide { display: flex; justify-content: center; align-items: center; height: 100%; flex: 1 0 auto; padding: 0 15px; pointer-events: none; }

.logo-carousel__slide-image { display: block; max-width: 100%; max-height: 100%; opacity: 0.8; }

@media only screen and (max-width: 1179px) { .logo-carousel__swiper-container { height: 110px; } }

@media only screen and (max-width: 991px) { .logo-carousel__swiper-container:before, .logo-carousel__swiper-container:after { width: 200px; } }

@media only screen and (max-width: 767px) { .logo-carousel__swiper-container { height: 80px; }
  .logo-carousel__swiper-container:before, .logo-carousel__swiper-container:after { width: 80px; } }

.main-video { width: 818px; }

.main-video__tablet { position: absolute; left: 55.74572%; top: 0; width: 29.91453%; border-radius: 8px; background-color: #111; }

.main-video__tablet:after { content: ''; display: block; padding-top: 128.6%; }

.main-video__tablet:before { content: ''; display: block; position: absolute; top: 16px; left: 12px; border-radius: 2.5px; background-color: #fff; width: 9.14286%; height: 3px; }

.main-video__macbook { position: relative; top: 35px; }

.main-video__macbook-video-background, .main-video__macbook-video { z-index: 1; position: absolute; width: 76.40587%; left: 11.98044%; top: 2.68949%; }

.main-video__macbook-video-background { background-color: #1d1e24; height: 76%; }

.main-video__macbook-frame { position: relative; width: 100%; }

.main-video__screen { position: absolute; left: -12px; top: 67.21992%; background-color: #111; width: 30.56235%; border-radius: 8px 8px 0 0; }

.main-video__screen:before, .main-video__screen:after { content: ''; display: block; }

.main-video__screen:before { position: absolute; height: 4px; border-radius: 2px; background-color: #fff; left: 12px; top: 8.33333%; width: 12.8%; }

.main-video__screen:after { padding-top: 76.8%; }

.main-video__screen-close { position: absolute; top: 7.29167%; right: 15px; width: 7px; height: 7px; background-image: inline("cross-white.svg"); background-size: cover; background-position: center center; }

.main-video__screen-video { z-index: 2; position: absolute; bottom: 0; width: 100%; }

@media only screen and (max-width: 1179px) { .main-video { width: 710px; } }

@media only screen and (max-width: 1060px) { .main-video { width: 660px; } }

@media only screen and (max-width: 991px) { .main-video { max-width: 100%; } }

@media only screen and (max-width: 767px) { .main-video__tablet { border-radius: 5px; }
  .main-video__tablet:before { height: 1px; top: 11px; }
  .main-video__macbook { top: 23px; }
  .main-video__screen { border-radius: 5px 5px 0 0; }
  .main-video__screen:before { height: 1px; }
  .main-video__screen-close { right: 11px; width: 5px; height: 5px; } }

.link-arrow { border: none; background-color: transparent; border-radius: 0; -webkit-appearance: none; text-decoration: none; user-select: none; cursor: pointer; font-size: 18px; font-weight: 700; text-decoration: none; color: #3f3f3f; transition: color 0.3s ease-out; user-select: none; cursor: pointer; }

.link-arrow:focus { outline: none; }

.link-arrow:hover { color: rgba(63, 63, 63, 0.9); transition: none; }

.link-arrow:hover .link-arrow__icon { transform: translateX(1px); transition: none; }

.link-arrow:active { color: rgba(63, 63, 63, 0.8); }

.link-arrow:active .link-arrow__icon { transform: translateX(2px); transition: none; }

.link-arrow__icon { width: 6px; height: 10px; margin-left: 10px; fill: #5359ea; transition: transform 0.3s ease-out; }

@media only screen and (max-width: 991px) { .link-arrow { font-size: 16px; } }

.fixed-scrolling { position: relative; }

.fixed-scrolling__index { position: absolute; top: 50%; right: 0; width: 60px; height: 55px; display: flex; align-items: center; justify-content: center; background-color: papayawhip; color: #2b2b2b; font-weight: 700; z-index: 100; font-size: 18px; }

.fixed-scrolling__video-area-container { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; position: absolute; left: 0; top: 0; z-index: 1; width: 47%; height: 100%; }

.fixed-scrolling__video-area { display: flex; align-items: center; justify-content: center; height: 100vh; }

.fixed-scrolling__video-area-container, .fixed-scrolling__video-area { background-color: #050505; }

.fixed-scrolling__mac { position: relative; max-width: 818px; width: 90%; }

.fixed-scrolling__mac-video-background { background-color: #1d1e24; height: 76%; }

.fixed-scrolling__mac-video-background, .fixed-scrolling__mac-video { position: absolute; width: 76.40587%; left: 11.98044%; top: 1.22249%; }

.fixed-scrolling__mac-video { visibility: hidden; opacity: 0; }

.fixed-scrolling__mac-frame { position: relative; width: 100%; }

.fixed-scrolling__content { padding-left: 53%; }

@media only screen and (max-width: 767px) { .fixed-scrolling__video-area-container { display: none; }
  .fixed-scrolling__content { padding-left: 0; } }

.fixed-scrolling-section { position: relative; height: 100vh; display: flex; align-items: center; }

.fixed-scrolling-section:before { content: ''; display: block; position: absolute; bottom: 0; left: -20%; height: 1px; width: 120%; background-color: #c8d8e0; }

.fixed-scrolling-section:last-of-type { border-bottom: none; }

.fixed-scrolling-section:last-of-type:before { display: none; }

.fixed-scrolling-section__inner { padding-top: 140px; padding-bottom: 140px; }

.fixed-scrolling-section__circle { margin-bottom: 42px; }

.fixed-scrolling-section__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 32px; margin-bottom: 13px; font-weight: 700; }

.fixed-scrolling-section__text { max-width: 480px; }

@media only screen and (max-width: 991px) { .fixed-scrolling-section__inner { padding-top: 130px; padding-bottom: 120px; } }

@media only screen and (max-width: 767px) { .fixed-scrolling-section { height: auto; }
  .fixed-scrolling-section:before { left: 50%; width: 100vw; transform: translateX(-50%); }
  .fixed-scrolling-section__inner { padding-top: 80px; padding-bottom: 80px; }
  .fixed-scrolling-section__circle { margin-bottom: 20px; width: 30px; height: 30px; }
  .fixed-scrolling-section__title { font-size: 24px; } }

.subtitle { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; font-size: 14px; text-transform: uppercase; color: #a0abb1; letter-spacing: 1px; }

.book-demo { position: relative; background-color: #191c20; color: #fff; }

.book-demo__container { padding-top: 165px; padding-bottom: 117px; }

.book-demo__content { display: flex; flex-direction: column; align-items: flex-start; position: relative; z-index: 1; }

.book-demo__title { margin-bottom: 20px; }

.book-demo__text { max-width: 470px; margin-bottom: 40px; color: #c0c0cd; }

.book-demo__button { text-transform: uppercase; font-size: 17px; }

.book-demo__image { position: absolute; right: -105px; bottom: -60px; width: 710px; }

@media only screen and (max-width: 1179px) { .book-demo__container { padding-top: 140px; }
  .book-demo__text { max-width: 400px; }
  .book-demo__image { bottom: 30px; width: 600px; } }

@media only screen and (max-width: 991px) { .book-demo__container { padding-top: 80px; padding-bottom: 80px; }
  .book-demo__text { max-width: 340px; }
  .book-demo__image { width: 500px; right: -110px; } }

@media only screen and (max-width: 767px) { .book-demo__container { padding-bottom: 40px; }
  .book-demo__image { position: relative; margin-top: 30px; bottom: auto; right: -30px; max-width: 100%; } }

.burger { position: relative; width: 26px; cursor: pointer; }

.burger:before { content: ' '; display: block; position: absolute; top: -15px; right: -15px; bottom: -15px; left: -15px; }

.burger__line { height: 1px; border-radius: 1px; background-color: #fff; margin-bottom: 5px; }

.burger__line:last-of-type { margin-bottom: 0; }

.mobile-menu { display: flex; align-items: center; justify-content: center; position: fixed; top: 0; left: 0; width: 100%; height: 100%; visibility: hidden; opacity: 0; background-color: #000; color: #fff; z-index: 10; }

.mobile-menu__header { position: absolute; top: 0; left: 0; width: 100%; }

.mobile-menu__header-container { display: flex; justify-content: space-between; align-items: center; height: 100px; }

.mobile-menu__logo { width: 125px; height: 17px; }

.mobile-menu__close { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; position: fixed; margin-right: 2px; right: 20px; width: 20px; height: 20px; background-image: inline("cross-white.svg"); z-index: 2; }

.mobile-menu__items { margin-top: 30px; display: flex; flex-direction: column; align-items: center; text-align: center; }

.mobile-menu__item { margin-bottom: 24px; }

.mobile-menu__item-link { display: flex; align-items: center; color: #fff; text-decoration: none; font-weight: 700; font-size: 22px; }

.mobile-menu__item-link-arrow { margin-left: 10px; width: 7px; height: 11px; fill: #fff; }

.mobile-menu__button { margin-top: 20px; font-size: 16px; }

.mobile-menu__back { fill: #fff; width: 28px; height: 30px; cursor: pointer; }

.mobile-menu__back:hover { opacity: 0.8; }

.mobile-menu__back:active { opacity: 0.6; }

.mobile-menu__sub { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #000; display: flex; align-items: center; justify-content: center; visibility: hidden; opacity: 0; }

@media only screen and (max-width: 767px) { .mobile-menu__header-container { height: 80px; } }

.logo { width: 150px; height: 17px; fill: #fff; }

@media only screen and (max-width: 991px) { .logo { width: 125px; height: 14px; } }

.logo._dark { fill: #2b2b2b; }

.footer { background-color: #191c20; padding-top: 66px; padding-bottom: 90px; color: #fff; }

.footer__logo { margin-bottom: 47px; }

.footer__line { background-color: rgba(255, 255, 255, 0.1); height: 1px; }

.footer__content { display: flex; justify-content: space-between; padding-top: 60px; }

.footer__company, .footer__copy { font-size: 14px; }

.footer__company { margin-bottom: 13px; }

.footer__copy { color: #a0abb1; }

.footer__social { display: flex; align-items: center; margin-top: 83px; }

.footer__social-link { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; position: relative; display: block; margin-right: 20px; cursor: pointer; }

.footer__social-link:hover { opacity: 0.8; }

.footer__social-link:active { opacity: 0.6; }

.footer__social-link:before { content: ''; display: block; position: absolute; top: -5px; right: -5px; bottom: -5px; left: -5px; }

.footer__social-link:last-child { margin-right: 0; }

.footer__social-link._facebook { width: 9px; height: 17px; background-image: inline("facebook.svg"); }

.footer__social-link._twitter { width: 16px; height: 14px; background-image: inline("twitter.svg"); }

.footer__social-link._instagram { width: 17px; height: 17px; background-image: inline("instagram.svg"); }

.footer__social-link._linkedin { top: -1px; width: 16px; height: 16px; background-image: inline("linkedin.svg"); }

.footer__links { display: flex; }

.footer__links-col { display: flex; flex-direction: column; margin-right: 13.05556vw; }

.footer__links-col:last-of-type { margin-right: 0; }

@media only screen and (min-width: 1441px) { .footer__links-col { margin-right: 188px; } }

.footer__link { font-size: 17px; font-weight: 700; text-decoration: none; color: #fff; margin-bottom: 23px; }

.footer__link:last-of-type { margin-bottom: 0; }

@media only screen and (max-width: 767px) { .footer { padding-bottom: 60px; }
  .footer__logo { margin-bottom: 40px; }
  .footer__content { padding-top: 50px; flex-direction: column; }
  .footer__social { margin-top: 30px; }
  .footer__col { margin-bottom: 50px; }
  .footer__links-col { margin-right: 50px; } }

@media only screen and (max-width: 374px) { .footer__links-col { margin-right: 40px; }
  .footer__link { font-size: 15px; } }

.testimonials { margin-top: -123px; }

.testimonials__box { position: relative; width: 994px; max-width: 85%; height: 402px; margin-left: auto; margin-right: auto; border-radius: 8px; overflow: hidden; will-change: transform; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); }

.testimonials__slides { display: flex; height: 100%; will-change: transform; }

.testimonials__next { border: none; background-color: transparent; border-radius: 0; -webkit-appearance: none; text-decoration: none; user-select: none; cursor: pointer; position: absolute; top: 0; right: 0; display: flex; align-items: center; justify-content: center; width: 89px; height: 100%; background-color: #191c20; cursor: pointer; transition: background-color 0.15s ease-in-out; }

.testimonials__next:focus { outline: none; }

.testimonials__next:active { background-color: #2f353d; }

.testimonials__next-icon { width: 14px; height: 22px; fill: #fff; }

@media only screen and (max-width: 1179px) { .testimonials__box { height: 430px; } }

@media only screen and (max-width: 991px) { .testimonials__box { max-width: 90%; height: 460px; }
  .testimonials__next { width: 80px; } }

@media only screen and (max-width: 767px) { .testimonials__box { height: auto; }
  .testimonials__next { display: flex; align-items: center; top: auto; bottom: 0; width: 100%; height: 67px; }
  .testimonials__next:before { content: 'Next Story'; color: #fff; font-size: 17px; margin-right: 10px; }
  .testimonials__next-icon { position: relative; width: 8px; height: 15px; } }

.testimonials-slide { display: flex; flex: 1 0 auto; }

.testimonials-slide__photo-area { position: relative; display: flex; align-items: center; justify-content: center; width: 33.9%; background-color: #fff; padding: 0 35px; }

.testimonials-slide__photo { max-width: 100%; }

.testimonials-slide__content-area { display: flex; flex-direction: column; justify-content: space-between; width: 66.1%; background-color: #5359ea; color: #fff; padding: 4.62777% 110px 3.52113% 6.53924%; }

.testimonials-slide__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; font-size: 32px; margin-bottom: 30px; }

.testimonials-slide__text { font-size: 15px; margin-bottom: 20px; color: inherit; line-height: 1.65; max-width: 430px; }

.testimonials-slide._small-font-size .testimonials-slide__text { font-size: 12px; }

@media only screen and (max-width: 1179px) { .testimonials-slide__photo-area { width: 30%; }
  .testimonials-slide__content-area { width: 70%; padding-right: 130px; } }

@media only screen and (max-width: 991px) { .testimonials-slide__photo-area { width: 30%; }
  .testimonials-slide__photo { max-width: 95%; }
  .testimonials-slide__content-area { width: 70%; padding-right: 110px; }
  .testimonials-slide__title { font-size: 32px; }
  .testimonials-slide__text { font-size: 15px; } }

@media only screen and (max-width: 767px) { .testimonials-slide { flex-direction: column; }
  .testimonials-slide__photo-area, .testimonials-slide__content-area { width: 100%; }
  .testimonials-slide__photo-area { height: 300px; flex-shrink: 0; }
  .testimonials-slide__content-area { padding: 30px 30px 100px; height: 100%; } }

.testimonials-author__name { font-size: 20px; font-weight: 700; margin-bottom: 3px; }

.testimonials-author__additional { font-size: 14px; color: inherit; }

[data-animation='clip'] { visibility: hidden; opacity: 0; }

[data-animation='clip'] .clip-element { display: block; will-change: transform; visibility: hidden; opacity: 0; }

[data-animation='jump'] { visibility: hidden; opacity: 0; will-change: transform; }

.brand-protection { padding-top: 170px; padding-bottom: 112px; }

.brand-protection__subtitle { text-align: center; margin-bottom: 18px; }

.brand-protection__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; margin-bottom: 114px; font-size: 60px; text-align: center; }

.brand-protection__items { display: flex; justify-content: center; }

.brand-protection__text { font-size: 28px; max-width: 1070px; margin-left: auto; margin-right: auto; text-align: center; margin-top: 108px; }

@media only screen and (max-width: 991px) { .brand-protection__title { font-size: 50px; margin-bottom: 80px; }
  .brand-protection__text { margin-top: 80px; font-size: 22px; } }

@media only screen and (max-width: 767px) { .brand-protection { padding-top: 80px; padding-bottom: 80px; }
  .brand-protection__title { font-size: 36px; margin-bottom: 50px; }
  .brand-protection__text { margin-top: 55px; font-size: 20px; }
  .brand-protection__items { flex-direction: column; align-items: center; } }

.brand-protection-item { position: relative; text-align: center; width: 272px; height: 272px; border-radius: 50%; background-color: #eaedef; padding-top: 79px; user-select: none; overflow: hidden; will-change: transform; margin: 0 10px; flex: 1 0 auto; font-weight: 500; }

.brand-protection-item:nth-of-type(2) { z-index: 1; }

.brand-protection-item:nth-of-type(2) .brand-protection-item__overlapping { display: block; position: absolute; top: 0; left: 100%; width: 100%; height: 100%; border-radius: 50%; border: 2px solid #fff; will-change: transform; }

.brand-protection-item__tick { display: flex; justify-content: center; align-items: center; width: 34px; height: 34px; border-radius: 50%; background-color: #eaedef; margin-left: auto; margin-right: auto; margin-bottom: 17px; }

.brand-protection-item__tick-icon { fill: #a0abb1; width: 13px; height: 10px; }

.brand-protection-item__tick._dark { background-color: #3f3f3f; }

.brand-protection-item__tick._dark .brand-protection-item__tick-icon { fill: #a0abb1; }

.brand-protection-item__title { font-size: 20px; margin-bottom: 26px; }

.brand-protection-item__lines:before, .brand-protection-item__lines:after { content: ''; display: block; height: 4px; border-radius: 2px; background-color: rgba(63, 63, 63, 0.1); margin-left: auto; margin-right: auto; }

.brand-protection-item__lines:before { width: 97px; margin-bottom: 7px; }

.brand-protection-item__lines:after { width: 65px; }

.brand-protection-item__lines._bright:before, .brand-protection-item__lines._bright:after { background-color: #666; }

.brand-protection-item._blue { background-color: #5359ea; color: #fff; }

.brand-protection-item._white { background-color: #fff; border: 2px solid #5359ea; }

.brand-protection-item._dark { background-color: #191c20; color: #fff; }

@media only screen and (max-width: 1179px) { .brand-protection-item { width: 234px; height: 234px; padding-top: 60px; margin: 0; }
  .brand-protection-item__title { font-size: 18px; } }

@media only screen and (max-width: 991px) { .brand-protection-item { width: 190px; height: 190px; }
  .brand-protection-item__tick { margin-bottom: 10px; width: 30px; height: 30px; }
  .brand-protection-item__title { font-size: 16px; margin-bottom: 20px; } }

@media only screen and (max-width: 767px) { .brand-protection-item { width: 210px; height: 210px; margin: 0 0 -20px; }
  .brand-protection-item:last-of-type { margin-bottom: 0; }
  .brand-protection-item:nth-of-type(2):before { display: none; } }

.book-screen { background-color: #fff; z-index: 10; }

.book-screen__inner { position: relative; min-height: 100%; }

.book-screen__content { width: 448px; padding-top: 64px; padding-bottom: 60px; }

.book-screen__header { display: flex; justify-content: space-between; margin-bottom: 64px; }

.book-screen__logo-link { font-size: 0; }

.book-screen__logo { fill: #1f1f1f; }

.book-screen__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 36px; margin-bottom: 12px; letter-spacing: -0.03em; }

.book-screen__text { margin-bottom: 36px; font-size: 14px; max-width: 340px; }

.book-screen__media-area { position: absolute; top: 0; right: 0; height: 100%; width: 48.61111vw; background-color: #070707; background-image: url("/media/img/book-demo-map@2x.jpg"); background-position: center top; background-repeat: no-repeat; background-size: 100%; }

@media only screen and (max-width: 1400px) { .book-screen__content { width: 500px; } }

@media only screen and (max-width: 1179px) { .book-screen__content { width: 48%; } }

@media only screen and (max-width: 767px) { .book-screen__title { font-size: 30px; }
  .book-screen__content { width: auto; }
  .book-screen__media-area { display: none; } }

.back { border: none; background-color: transparent; border-radius: 0; -webkit-appearance: none; text-decoration: none; user-select: none; cursor: pointer; color: #666; font-size: 14px; display: flex; align-items: center; }

.back:focus { outline: none; }

.back:hover { opacity: 0.8; }

.back:active { opacity: 0.6; }

.back__icon { transform: scale(-1); fill: #666; width: 6px; height: 10px; margin-right: 7px; }

.input-label { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; display: block; font-size: 15px; font-weight: 700; color: #2b2b2b; margin-bottom: 11px; letter-spacing: -0.03em; }

.input-field { position: relative; border: 1px solid #a0abb1; border-radius: 4px; height: 40px; font-size: 16px; display: flex; }

.input-field__input { border: none; border-radius: 0; -webkit-appearance: none; background-color: transparent; padding: 0 8px; width: 100%; flex-shrink: 1; }

.input-field__show-password { position: absolute; right: 15px; top: 50%; transform: translateY(-50%); cursor: pointer; z-index: 1; }

.input-field__show-password:hover { opacity: 0.8; }

.input-field__show-password:active { opacity: 0.6; }

.input-field__show-password:before { content: ''; display: block; position: absolute; top: -10px; right: -10px; bottom: -10px; left: -10px; }

.input-field__show-password svg { width: 19px; height: 11px; }

.input-field[data-required]:after { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; content: '*'; position: absolute; right: 11px; top: calc(50% - 8px); display: block; font-size: 18px; color: #ff7c8c; opacity: 0; pointer-events: none; transition: opacity 0.3s ease-out; }

.input-field._error-required[data-required]:after { opacity: 1; }

.phone-code { height: 100%; }

.phone-code__current { display: flex; align-items: center; position: relative; height: 100%; overflow: hidden; width: 53px; }

.phone-code__current:before { content: ''; display: block; position: absolute; right: 0; height: 30px; width: 1px; background-color: #a0abb1; }

.phone-code__current:after { content: ''; display: block; position: absolute; top: 50%; margin-top: -2px; right: 7px; background-image: inline("dropdown-arrow-grey.svg"); width: 5px; height: 5px; background-size: cover; }

.phone-code__select { font-family: ProximaNova, Arial, sans-serif; border: none; box-shadow: none; background-color: transparent; background-image: none; -webkit-appearance: none; font-size: 16px; color: #a0abb1; padding-left: 10px; }

.phone-code__select:focus { outline: none; }

.book-demo-form__inputs { margin-bottom: 38px; }

.book-demo-form__row { display: flex; align-items: center; margin: 0 -14px 26px; }

.book-demo-form__row:last-of-type { margin-bottom: 0; }

.book-demo-form__col { flex: 1 0 auto; width: 50%; padding: 0 14px; }

.book-demo-form__checkbox { margin-bottom: 9px; }

.book-demo-form__checkbox:last-of-type { margin-bottom: 0; }

.book-demo-form__submit { width: 100%; margin-top: 38px; }

@media only screen and (max-width: 767px) { .book-demo-form__row { flex-wrap: wrap; margin-bottom: 20px; }
  .book-demo-form__col { width: 100%; margin-bottom: 20px; }
  .book-demo-form__col:last-child { margin-bottom: 0; } }

.checkbox { position: relative; user-select: none; }

.checkbox__input { position: absolute; visibility: hidden; opacity: 0; }

.checkbox__input:checked + .checkbox__label:after { opacity: 1; }

.checkbox__label { position: relative; display: block; color: #666; font-size: 14px; padding-left: 35px; line-height: 1.5; }

.checkbox__label:active:after { transition-duration: 0.05s; }

.checkbox__label:before { content: ''; display: block; position: absolute; top: 1px; left: 0; width: 18px; height: 18px; border-radius: 3px; border: 1px solid #a0abb1; }

.checkbox__label:after { content: ''; display: block; position: absolute; left: 2px; top: 4px; width: 15px; height: 12px; background-image: inline("tick.svg"); background-size: cover; background-position: center center; opacity: 0; transition: opacity 0.25s ease-out; }

.checkbox__required:after { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; content: '*'; position: absolute; color: #ff7c8c; margin-top: -2px; margin-left: 4px; font-size: 18px; font-weight: 700; }

.checkbox._bold { font-weight: 700; }

.checkbox._bold .checkbox__label { color: #2b2b2b; }

.link-text { color: #5359ea; text-decoration: none; font-weight: 700; background-image: linear-gradient(#5359ea, #5359ea); background-size: 100% 2px; background-repeat: no-repeat; background-position: center bottom; }

.link-text:hover { opacity: 0.8; }

.link-text:active { opacity: 0.6; }

.errors { margin-bottom: 18px; }

.errors__item { position: relative; color: #ff7c8c; margin-bottom: 6px; padding-left: 15px; }

.errors__item:last-of-type { margin-bottom: 0; }

.errors__item:before { content: ''; position: absolute; top: 8px; left: 0; display: block; width: 4px; height: 4px; border-radius: 50%; background-color: #ff7c8c; }

.success { margin-bottom: 18px; }

.success__item { position: relative; color: #29c18b; margin-bottom: 6px; padding-left: 15px; }

.success__item:last-of-type { margin-bottom: 0; }

.success__item:before { content: ''; position: absolute; top: 8px; left: 0; display: block; width: 4px; height: 4px; border-radius: 50%; background-color: #29c18b; }

.book-demo-carousel { position: relative; visibility: hidden; opacity: 0; color: #fff; padding-bottom: 60px; overflow: hidden; background-color: #09090a; height: 100%; }

.book-demo-carousel__slides { display: flex; background-color: inherit; }

.book-demo-carousel__slide { flex: 1 0 100%; background-color: inherit; }

.book-demo-carousel__image-wrapper { position: relative; overflow: hidden; margin-bottom: 154px; }

.book-demo-carousel__image-wrapper:before { content: ''; display: block; padding-top: 40vh; min-height: 400px; }

.book-demo-carousel__image { position: absolute; left: 0; top: 0; width: 100%; height: 100%; object-fit: cover; object-position: center center; }

.book-demo-carousel__content { text-align: center; max-width: 492px; margin-left: auto; margin-right: auto; }

.book-demo-carousel__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 38px; margin-bottom: 15px; }

.book-demo-carousel__text { color: #c0c0cd; font-size: 18px; }

.book-demo-carousel__pagination { display: flex; justify-content: center; align-items: center; margin-top: 90px; }

.book-demo-carousel__pagination .swiper-pagination-bullet { display: block; position: relative; width: 16px; height: 3px; margin: 0 5px; cursor: pointer; }

.book-demo-carousel__pagination .swiper-pagination-bullet:before, .book-demo-carousel__pagination .swiper-pagination-bullet:after { content: ''; display: block; position: absolute; }

.book-demo-carousel__pagination .swiper-pagination-bullet:after { top: -5px; right: -5px; bottom: -5px; left: -5px; }

.book-demo-carousel__pagination .swiper-pagination-bullet:before { transform: translate(-50%, -50%); top: 50%; left: 50%; height: 100%; width: 100%; border-radius: 2px; background-color: #5c5c5c; transition: width 0.3s ease-out, height 0.3s ease-out, background 0.3s ease-out, border-radius 0.3s ease-out; }

.book-demo-carousel__pagination .swiper-pagination-bullet-active:before { width: 10px; height: 10px; border-radius: 5px; background-color: #fff; }

.circle-rhombus { position: relative; width: 37px; height: 37px; border-radius: 50%; background-color: rgba(83, 89, 234, 0.15); }

.circle-rhombus:before { content: ''; display: block; position: absolute; top: 50%; left: 50%; width: 35.13514%; height: 35.13514%; border-radius: 1.5px; background-color: #5359ea; transform: translate(-50%, -50%) rotate(-45deg); }

@media only screen and (max-width: 1179px) { .circle-rhombus { width: 34px; height: 34px; } }

@media only screen and (max-width: 991px) { .circle-rhombus { width: 32px; height: 32px; } }

@media only screen and (max-width: 767px) { .circle-rhombus { width: 28px; height: 28px; } }

.text-switcher { position: relative; height: 280px; }

.text-switcher__buttons { position: absolute; right: 68%; display: flex; flex-direction: column; align-items: flex-end; text-align: right; }

.text-switcher__slides-container { padding-left: 50.42735%; }

@media only screen and (max-width: 767px) { .text-switcher__slides-container { padding-left: 0; } }

.text-switcher__slide-circle { margin-bottom: 19px; will-change: transform; }

@media only screen and (max-width: 1179px) { .text-switcher__buttons { right: 64%; } }

@media only screen and (max-width: 991px) { .text-switcher__buttons { right: 62%; } }

@media only screen and (max-width: 767px) { .text-switcher { height: auto; }
  .text-switcher__buttons { position: static; align-items: flex-start; margin-bottom: 40px; } }

.text-switcher__slides-container { height: 270px; }

.text-switcher-button { display: flex; align-items: center; justify-content: flex-end; font-size: 26px; font-weight: 700; color: #666; margin-bottom: 35px; cursor: pointer; transition: transform 0.35s ease-out; transform-origin: right center; will-change: transform; user-select: none; }

.text-switcher-button:hover { opacity: 0.8; }

.text-switcher-button:active { opacity: 0.6; }

.text-switcher-button:last-of-type { margin-bottom: 0; }

.text-switcher-button__circle { margin-right: 12px; transition: opacity 0.15s ease-out; flex: 1 0 auto; }

.text-switcher-button._active { color: #2b2b2b; transition: transform 0.35s ease-out; transform: scale(1.38); }

.text-switcher-button._active .text-switcher-button__circle { opacity: 0; }

@media only screen and (max-width: 1179px) { .text-switcher-button { font-size: 24px; } }

@media only screen and (max-width: 991px) { .text-switcher-button { font-size: 18px; max-width: 240px; } }

@media only screen and (max-width: 767px) { .text-switcher-button { justify-content: flex-start; flex-direction: row-reverse; margin-bottom: 15px; transform-origin: left center; max-width: none; }
  .text-switcher-button__circle { margin-left: 10px; } }

.text-switcher-slide { position: absolute; }

.text-switcher-slide__title, .text-switcher-slide__text { visibility: hidden; opacity: 0; will-change: transform; }

.text-switcher-slide__title { font-size: 56px; font-weight: 700; margin-bottom: 25px; }

.text-switcher-slide__text { max-width: 480px; font-size: 22px; }

@media only screen and (max-width: 1179px) { .text-switcher-slide__title { font-size: 38px; margin-bottom: 20px; }
  .text-switcher-slide__text { font-size: 20px; } }

@media only screen and (max-width: 991px) { .text-switcher-slide__title { font-size: 32px; } }

@media only screen and (max-width: 767px) { .text-switcher-slide__circle { margin-bottom: 7px; }
  .text-switcher-slide__title { font-size: 28px; margin-bottom: 10px; } }

.privacy-sections { padding-bottom: 90px; }

.privacy-sections__header { margin-bottom: 84px; }

.privacy-sections__title { margin-bottom: 18px; }

.privacy-sections__date { color: #666; padding-left: 0.15em; }

.privacy-sections__date-highlight { color: #000; font-weight: 700; }

.privacy-sections__content { position: relative; padding-left: 50.42735%; }

@media only screen and (max-width: 767px) { .privacy-sections__content { padding-left: 0; } }

.privacy-sections__navigation-container { position: absolute; left: 0; width: 300px; }

.privacy-sections__navigation { display: flex; flex-direction: column; align-items: flex-start; }

.privacy-sections__navigation-item { display: flex; align-items: center; font-size: 23px; font-weight: 700; margin-bottom: 28px; color: #c4c4c4; cursor: pointer; user-select: none; }

.privacy-sections__navigation-item:last-of-type { margin-bottom: 0; }

.privacy-sections__navigation-item:hover { color: #ababab; }

.privacy-sections__navigation-item:active { color: #9e9e9e; }

.privacy-sections__navigation-item._active { color: #2b2b2b; }

.privacy-sections__navigation-item-icon { width: 5px; height: 8px; fill: #5359ea; margin-right: 22px; }

.privacy-sections__navigation[data-hidden] { visibility: hidden; opacity: 0; position: absolute; }

.privacy-sections__section { margin-bottom: 80px; }

.privacy-sections__section:last-of-type { margin-bottom: 0; }

@media only screen and (max-width: 1179px) { .privacy-sections__header { margin-bottom: 66px; } }

@media only screen and (max-width: 991px) { .privacy-sections__header { margin-bottom: 50px; }
  .privacy-sections__navigation-container { display: none; }
  .privacy-sections__content { padding-left: 0; max-width: 90%; } }

@media only screen and (max-width: 767px) { .privacy-sections__header { margin-bottom: 40px; }
  .privacy-sections__section { margin-bottom: 50px; } }

.text-big { font-size: 30px; line-height: 1.5; font-weight: 700; }

@media only screen and (max-width: 991px) { .text-big { font-size: 22px; } }

@media only screen and (max-width: 767px) { .text-big { font-size: 20px; } }

.circle-with-chevron { display: flex; justify-content: center; align-items: center; width: 36px; height: 36px; border-radius: 50%; border: 2px solid #e7e7e7; }

.circle-with-chevron__icon { width: 5px; height: 8px; fill: #5359ea; }

.text-with-icon { position: relative; padding-left: 55px; max-width: 320px; }

.text-with-icon:before { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; content: ''; position: absolute; top: -1px; left: 0; display: block; width: 34px; height: 34px; background-image: inline("tick-circle.svg"); }

.text-with-icon__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 26px; font-weight: 700; margin-bottom: 7px; }

.text-with-icon__text { font-size: 18px; color: #c0c0cd; padding-left: 2px; }

.text-with-icon._large { padding-left: 70px; }

.text-with-icon._large:before { top: 1px; width: 40px; height: 40px; }

.text-with-icon._large .text-with-icon__title { font-size: 30px; }

.text-with-icon._large .text-with-icon__text { font-size: 20px; }

@media only screen and (max-width: 991px) { .text-with-icon { width: 290px; max-width: none; } }

@media only screen and (max-width: 767px) { .text-with-icon { width: 260px; }
  .text-with-icon__title { font-size: 22px; }
  .text-with-icon._large { padding-left: 55px; }
  .text-with-icon._large:before { width: 34px; height: 34px; }
  .text-with-icon._large .text-with-icon__title { font-size: 22px; }
  .text-with-icon._large .text-with-icon__text { font-size: 18px; } }

.cta-block { position: relative; padding-left: 60px; padding-right: 60px; }

.cta-block__box { position: relative; padding: 74px 60px 65px; max-width: 1280px; margin-left: auto; margin-right: auto; background-color: #4b4ccf; background-image: url("/media/img/pattern-wave.png"); background-size: cover; color: #fff; text-align: center; }

.cta-block__title { margin-bottom: 18px; font-size: 60px; }

.cta-block__text { margin-bottom: 55px; }

.cta-block__buttons { display: flex; justify-content: center; }

.cta-block__button:first-of-type { margin-right: 32px; }

.cta-block._dark { background-color: #191c20; }

.cta-block._before-footer { padding-bottom: 40px; }

.cta-block._before-footer:before { content: ''; position: absolute; bottom: 0; left: 0; display: block; background-color: #191c20; height: 50%; width: 100%; }

@media only screen and (max-width: 991px) { .cta-block { padding-left: 0; padding-right: 0; }
  .cta-block__title { font-size: 56px; } }

@media only screen and (max-width: 767px) { .cta-block__box { padding-left: 20px; padding-right: 20px; }
  .cta-block__title { font-size: 48px; }
  .cta-block__button:first-of-type { margin-right: 20px; } }

@media only screen and (max-width: 374px) { .cta-block__buttons { flex-direction: column; align-items: center; }
  .cta-block__button:first-of-type { margin-bottom: 30px; margin-right: 0; } }

.common-intro { position: relative; text-align: center; }

.common-intro:before { content: ''; display: block; position: absolute; left: 0; top: -11.11111vw; width: 100%; height: 100%; background-image: url("/media/img/pattern-houses2.jpg"); background-size: 100%; background-position: center top; background-repeat: no-repeat; }

.common-intro__title { font-size: 72px; max-width: 1100px; margin-left: auto; margin-right: auto; text-align: center; margin-bottom: 40px; }

.common-intro__text { font-size: 20px; max-width: 690px; margin-left: auto; margin-right: auto; margin-bottom: 40px; }

.common-intro__buttons { display: flex; justify-content: center; }

.common-intro__button:first-of-type { margin-right: 31px; }

.common-intro__bottom-text { font-size: 16px; }

.common-intro__image { margin-top: 115px; max-width: 90%; }

.common-intro._dark { position: static; color: #fff; background-color: #050505; }

.common-intro._dark:before { top: 0; background: #050505 url("/media/img/pattern-background.jpg") no-repeat center top; background-size: cover; }

@media only screen and (max-width: 1179px) { .common-intro__title { font-size: 60px; } }

@media only screen and (max-width: 991px) { .common-intro__title { font-size: 50px; }
  .common-intro__image { margin-top: 100px; } }

@media only screen and (max-width: 767px) { .common-intro__title { font-size: 36px; }
  .common-intro__button:first-of-type { margin-right: 20px; }
  .common-intro__image { margin-top: 60px; max-width: 100%; } }

@media only screen and (max-width: 374px) { .common-intro__title { font-size: 32px; }
  .common-intro__buttons { flex-direction: column; align-items: center; }
  .common-intro__button:first-of-type { margin-bottom: 30px; margin-right: 0; } }

.nav-dropdown { position: absolute; left: 0; top: 36px; width: 408px; visibility: hidden; opacity: 0; will-change: opacity; }

.nav-dropdown:before { content: ''; display: block; position: absolute; top: -36px; left: 0; height: 36px; width: 150px; }

.nav-dropdown__box { background-color: #fff; border-radius: 4px; border-top: 5px solid #5359ea; color: #2b2b2b; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); }

.nav-dropdown__items { display: flex; flex-wrap: wrap; }

.nav-dropdown-item { display: flex; align-items: center; padding: 21px 20px; border-radius: 4px; width: 50%; transition: background-color 0.25s ease-in; text-decoration: none; color: #2b2b2b; }

.nav-dropdown-item:hover { background-color: #5359ea; color: #fff; transition: none; }

.nav-dropdown-item:hover .nav-dropdown-item__icon, .nav-dropdown-item:hover .nav-dropdown-item__title, .nav-dropdown-item:hover .nav-dropdown-item__text { transition: none; }

.nav-dropdown-item:hover .nav-dropdown-item__icon { border-color: rgba(255, 255, 255, 0.5); }

.nav-dropdown-item:hover .nav-dropdown-item__icon .el { fill: #fff; stroke: #fff; transition: none; }

.nav-dropdown-item:hover .nav-dropdown-item__icon .el._no-hover-fill { fill: #5359ea; }

.nav-dropdown-item:hover .nav-dropdown-item__text { color: #fff; }

.nav-dropdown-item:active { opacity: 0.8; }

.nav-dropdown-item:first-of-type, .nav-dropdown-item:nth-of-type(2) { border-radius: 0 0 4px 4px; }

.nav-dropdown-item__icon { margin-right: 18px; }

.nav-dropdown-item__icon .el { transition: fill 0.35s ease-in-out, stroke 0.35s ease-in-out; }

.nav-dropdown-item__icon._new-agent { width: 29px; height: 28px; }

.nav-dropdown-item__icon._local { width: 24px; height: 24px; }

.nav-dropdown-item__icon._regional { width: 27px; height: 27px; }

.nav-dropdown-item__icon._national { width: 30px; height: 24px; }

.nav-dropdown-item__title { font-size: 17px; font-weight: 700; transition: color 0.35s ease-in-out; }

.nav-dropdown-item__text { font-size: 14px; font-weight: 400; transition: color 0.35s ease-in-out; }

#password-reset { display: none; }

.auth-screen { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; background-color: #fff; background-image: url("/media/img/pattern-houses2.jpg"); background-size: 100%; background-position: center top; background-repeat: no-repeat; overflow-y: auto; -webkit-overflow-scrolling: touch; }

.auth-screen__content { min-height: 100%; padding: 60px 0; display: flex; justify-content: center; align-items: center; }

.auth-screen__box-container { display: flex; flex-direction: column; align-items: center; width: 100%; }

.auth-screen__logo { margin-bottom: 87px; }

.auth-screen__box { padding: 38px; background-color: #fff; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); max-width: 460px; width: 100%; }

.auth-screen__bottom-text { margin-top: 18px; color: #919191; }

@media only screen and (max-width: 767px) { .auth-screen__logo { margin-bottom: 60px; }
  .auth-screen__box { padding: 25px; } }

.auth-box__title { text-align: center; font-weight: 700; font-size: 36px; margin-bottom: 36px; }

.auth-box__subtitle { text-align: center; line-height: 1.6; color: #666; margin-bottom: 38px; }

.auth-box__input { margin-bottom: 28px; }

.auth-box__flex-container { display: flex; justify-content: space-between; align-items: center; }

.auth-box__link { color: #666666; font-size: 14px; text-decoration: underline; }

.auth-box__button { margin-top: 26px; width: 100%; }

.auth-box__back-container { margin-top: 30px; display: flex; justify-content: center; }

.auth-box__back { text-align: center; color: #666; }

.auth-box__success-message { display: flex; justify-content: center; color: #29c18b; font-size: 16px; margin-bottom: 60px; }

.auth-box__success-message-inner { display: flex; align-items: center; }

.auth-box__success-message-icon { fill: #29c18b; width: 18px; height: 18px; margin-right: 10px; }

@media only screen and (max-width: 767px) { .auth-box__flex-container { flex-direction: column; }
  .auth-box__remember-me { margin-bottom: 10px; } }

/***=== SECTIONS ===***/
.section-roi { position: relative; color: #fff; padding-top: 105px; }

.section-roi:before { background: #050505 url("/media/img/pattern-background.jpg") no-repeat center top; background-size: cover; content: ''; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.section-roi__container { position: relative; }

.section-roi__header { display: flex; justify-content: space-between; margin-bottom: 125px; }

.section-roi__header-col { width: 46.15385%; }

.section-roi__title { margin-bottom: 23px; }

.section-roi__button { position: relative; top: 15px; right: 17px; }

.section-roi__text { color: #c0c0cd; }

.section-roi__content { position: relative; background-color: #fff; color: #2b2b2b; padding-bottom: 30px; }

.section-roi__content-container { position: relative; display: flex; align-items: flex-start; }

.section-roi__box { position: relative; top: -40px; margin-right: 12.39316%; flex: 1 0 auto; }

.section-roi__features { padding-top: 80px; }

@media only screen and (max-width: 1179px) { .section-roi__box { margin-right: 11.11111%; }
  .section-roi__features { padding-top: 60px; } }

@media only screen and (max-width: 991px) { .section-roi { padding-top: 110px; }
  .section-roi__box { margin-right: 0; margin-bottom: 60px; }
  .section-roi__content { padding-bottom: 90px; }
  .section-roi__content-container { flex-direction: column; }
  .section-roi__features { padding-top: 0; } }

@media only screen and (max-width: 767px) { .section-roi { padding-top: 80px; }
  .section-roi__header { display: block; margin-bottom: 90px; }
  .section-roi__header-col { width: auto; margin-bottom: 30px; }
  .section-roi__box { margin-bottom: 20px; }
  .section-roi__button { position: static; }
  .section-roi__content { padding-bottom: 80px; } }

.roi-box { position: relative; display: flex; max-width: 650px; }

.roi-box:before { content: ''; display: block; position: absolute; left: 50%; transform: translateX(-50%); bottom: -8px; width: 91.7%; height: 277px; border-radius: 20px; filter: blur(50px); background-image: linear-gradient(to right, rgba(135, 135, 135, 0.5) 50%, rgba(83, 89, 234, 0.5) 50%); will-change: transform; }

.roi-box__col { position: relative; }

.roi-box__col:first-of-type { display: flex; flex-direction: column; align-items: center; padding-top: 70px; padding-bottom: 31px; width: 53%; background-color: #fff; border-radius: 8px 0 0 8px; }

.roi-box__col:last-of-type { display: flex; flex-direction: column; justify-content: space-between; padding: 70px 26px 40px 32px; width: 47%; background-color: #5359ea; border-radius: 0 8px 8px 0; }

.roi-box__circle { margin-bottom: 70px; }

.roi-box__text { font-size: 16px; max-width: 230px; text-align: center; }

.roi-box__text-large { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; line-height: 1.36; font-size: 28px; color: #fff; font-weight: 700; }

.roi-box__button { max-width: 224px; }

@media only screen and (max-width: 1179px) { .roi-box { max-width: 590px; }
  .roi-box__text-large { font-size: 26px; } }

@media only screen and (max-width: 767px) { .roi-box { flex-direction: column; border-radius: 8px; overflow: hidden; width: 100%; }
  .roi-box__col:first-of-type, .roi-box__col:last-of-type { border-radius: 0; width: auto; padding: 30px; }
  .roi-box__circle { margin-bottom: 30px; }
  .roi-box__text-large { margin-bottom: 30px; } }

.roi-circle { display: flex; flex-direction: column; align-items: center; position: relative; width: 214px; height: 214px; text-align: center; padding-top: 30px; user-select: none; }

.roi-circle__border { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

.roi-circle__circle { position: relative; width: 33px; height: 33px; border-radius: 50%; background-color: rgba(83, 89, 234, 0.15); margin-bottom: 12px; }

.roi-circle__circle:before { content: ''; display: block; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 50%; background-color: #5359ea; width: 12px; height: 12px; }

.roi-circle__value, .roi-circle__caption { color: #3f3f3f; }

.roi-circle__value { font-family: ProximaNovaCond, "Courier New", Verdana; display: flex; align-items: center; font-size: 50px; font-weight: 700; line-height: 1; margin-bottom: 9px; }

.roi-circle__value-units { font-size: 25px; line-height: 1; margin-left: 5px; }

.roi-circle__caption { text-transform: uppercase; font-size: 12px; font-weight: 700; letter-spacing: 2px; margin-bottom: 18px; }

.roi-circle__lines:before, .roi-circle__lines:after { content: ''; display: block; height: 4px; border-radius: 2px; background-color: rgba(63, 63, 63, 0.1); margin-left: auto; margin-right: auto; }

.roi-circle__lines:before { margin-bottom: 6px; width: 87px; }

.roi-circle__lines:after { width: 58px; }

.section-roi-feature { position: relative; margin-bottom: 50px; }

.section-roi-feature:last-of-type { margin-bottom: 0; }

.section-roi-feature__icon { display: flex; justify-content: center; align-items: center; position: absolute; top: 0; left: -22px; width: 37px; height: 37px; border-radius: 50%; background-color: #e5e6fc; transform: translateX(-100%); }

.section-roi-feature__icon:before { content: ''; display: block; background-size: cover; }

.section-roi-feature__icon._list:before { background-image: inline("icon-list.svg"); height: 14px; width: 14px; }

.section-roi-feature__icon._chart:before { background-image: inline("icon-chart.svg"); height: 18px; width: 21px; }

.section-roi-feature__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 32px; font-weight: 700; margin-bottom: 11px; }

@media only screen and (max-width: 1179px) { .section-roi-feature { margin-bottom: 70px; }
  .section-roi-feature__title { font-size: 28px; }
  .section-roi-feature__text { font-size: 18px; max-width: 300px; } }

@media only screen and (max-width: 991px) { .section-roi-feature { padding-left: 64px; margin-bottom: 60px; }
  .section-roi-feature__icon { left: 0; transform: translateX(0); } }

@media only screen and (max-width: 767px) { .section-roi-feature { margin-bottom: 45px; padding-left: 60px; }
  .section-roi-feature__title { font-size: 24px; } }

.section-number-boxes { padding-bottom: 64px; }

.section-number-boxes__items { display: flex; flex-wrap: wrap; margin: 0 -23px; }

.section-number-boxes__items-col { padding: 0 23px; width: 25%; }

.section-number-boxes__annotations { margin-top: 37px; }

.section-number-boxes__annotations:before { content: ''; display: block; height: 1px; background-color: #c8d8e0; width: 47.86325%; margin-bottom: 25px; }

.section-number-boxes__annotations-cols { position: relative; display: flex; flex-wrap: wrap; flex: 0 1; justify-content: flex-start; margin: 0 -23px; }

.section-number-boxes__annotations-col { width: 25%; padding: 0 23px; }

.section-number-boxes__annotation { position: relative; font-size: 13px; padding-left: 7px; line-height: 1.65; }

.section-number-boxes__annotation-asterisk { position: absolute; left: 3px; color: #29c18b; transform: translateX(-100%); font-weight: 700; }

@media only screen and (max-width: 1179px) { .section-number-boxes__items { margin: 0 -10px; }
  .section-number-boxes__items-col { padding: 0 10px; } }

@media only screen and (max-width: 991px) { .section-number-boxes__items { margin-bottom: -30px; justify-content: center; }
  .section-number-boxes__items-col { width: 45%; margin-bottom: 30px; }
  .section-number-boxes__annotations:before { position: relative; left: 50%; margin-left: -45%; width: 90%; }
  .section-number-boxes__annotations-cols { justify-content: center; margin-bottom: -20px; }
  .section-number-boxes__annotations-col { width: 45%; margin-bottom: 20px; } }

@media only screen and (max-width: 767px) { .section-number-boxes { padding-bottom: 40px; }
  .section-number-boxes__items-col { width: 100%; max-width: 260px; }
  .section-number-boxes__annotations-col { width: 90%; }
  .section-number-boxes__annotation { padding-left: 12px; } }

.section-number-boxes-item { background-color: #fff; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); padding: 37px 31px 27px; }

.section-number-boxes-item__tick { display: flex; justify-content: center; align-items: center; border-radius: 50%; width: 34px; height: 34px; margin-bottom: 24px; border: 2px solid #eaedef; }

.section-number-boxes-item__tick-icon { width: 13px; height: 10px; fill: #5359ea; }

.section-number-boxes-item__value { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; position: relative; color: #5359ea; font-weight: 700; font-size: 36px; }

.section-number-boxes-item__value:after { content: ''; display: block; height: 1px; max-width: 124px; background-color: #c8d8e0; margin-top: 20px; margin-bottom: 24px; }

.section-number-boxes-item__value-units { font-family: ProximaNova, Arial, sans-serif; font-size: 0.5em; margin-left: 4px; }

.section-number-boxes-item__value-annotation { position: absolute; right: 0; top: -0.5em; margin-left: 8px; color: #29c18b; font-size: 20px; }

.section-number-boxes-item__text { font-weight: 700; font-size: 18px; line-height: 1.6; }

@media only screen and (max-width: 1179px) { .section-number-boxes-item { padding: 30px 22px 22px; }
  .section-number-boxes-item__tick { margin-bottom: 17px; }
  .section-number-boxes-item__value { font-size: 32px; } }

@media only screen and (max-width: 991px) { .section-number-boxes-item { padding-bottom: 30px; }
  .section-number-boxes-item__value:after { max-width: none; width: 66%; } }

.section-logos { padding-top: 160px; padding-bottom: 160px; }

.section-logos__items { display: flex; align-items: center; flex-wrap: wrap; margin-bottom: -125px; }

.section-logos__col { display: flex; justify-content: center; align-items: center; width: 16.66%; margin-bottom: 125px; }

.section-logos__item { opacity: 0.8; max-width: 70%; height: auto; }

.section-logos._dark-background { background-color: #191c20; }

.section-logos._dark-background .section-logos__item { opacity: 1; }

.section-logos._alt-layout .section-logos__col { width: 25%; }

@media only screen and (max-width: 1179px) { .section-logos { padding-top: 140px; padding-bottom: 140px; } }

@media only screen and (max-width: 991px) { .section-logos { padding-top: 120px; padding-bottom: 120px; } }

@media only screen and (max-width: 767px) { .section-logos { padding-top: 80px; padding-bottom: 80px; }
  .section-logos__items { margin-bottom: -80px; }
  .section-logos._alt-layout .section-logos__col, .section-logos .section-logos__col { width: 50%; margin-bottom: 80px; } }

@media only screen and (max-width: 374px) { .section-logos__col { width: 100%; } }

.section-content { padding-top: 140px; padding-bottom: 70px; }

.section-content__container { display: flex; }

.section-content__col._text { display: flex; align-items: center; width: 58.5%; padding-top: 10px; padding-right: 50px; }

.section-content__col._visual { position: relative; width: 41.5%; }

.section-content__text-content { padding-bottom: 100px; }

.section-content__title { font-size: 60px; margin-bottom: 22px; }

.section-content__text { max-width: 80%; }

.section-content__list { display: flex; flex-wrap: wrap; margin-top: 125px; max-width: 600px; margin-bottom: -48px; }

.section-content__list-item { width: 50%; margin-bottom: 48px; }

.section-content__link-container { display: flex; justify-content: flex-end; margin-bottom: 35px; }

.section-content__visual { width: 50.69444vw; }

.section-content__media { max-width: 100%; }

.section-content__link { font-size: 16px; }

.section-content._dark { border-top: 3px solid #5359ea; background-color: #191c20; color: #fff; }

.section-content._text-content-narrower .section-content__col._text { width: 53%; }

.section-content._text-content-narrower .section-content__col._visual { width: 47%; }

.section-content._reverse .section-content__container { flex-direction: row-reverse; }

.section-content._reverse .section-content__col._text { padding-right: 0; padding-left: 50px; }

.section-content._reverse .section-content__link-container { justify-content: flex-start; }

.section-content._reverse .section-content__visual { position: relative; left: 100%; transform: translateX(-100%); text-align: right; }

@media only screen and (max-width: 1179px) { .section-content__title { font-size: 48px; } }

@media only screen and (max-width: 991px) { .section-content { padding-top: 100px; padding-bottom: 90px; }
  .section-content__col._text { padding-right: 40px; }
  .section-content__title { font-size: 32px; }
  .section-content__text { max-width: 90%; }
  .section-content__link-container { margin-bottom: 20px; }
  .section-content__list { margin-top: 150px; width: 530px; }
  .section-content._reverse .section-content__col._text { padding-right: 0; padding-left: 40px; } }

@media only screen and (max-width: 767px) { .section-content { padding-top: 80px; padding-bottom: 80px; }
  .section-content__container { flex-direction: column; }
  .section-content__col._text, .section-content__col._visual { width: auto; }
  .section-content__col._text { margin-bottom: 32px; }
  .section-content__text-content { padding-bottom: 0; }
  .section-content__link-container { margin-bottom: 40px; justify-content: flex-start; }
  .section-content__visual { width: 90%; }
  .section-content__list { margin-top: 50px; margin-bottom: -20px; }
  .section-content__list-item { width: 100%; }
  .section-content._text-content-narrower .section-content__col._text, .section-content._text-content-narrower .section-content__col._visual { width: auto; }
  .section-content._reverse .section-content__container { flex-direction: column; }
  .section-content._reverse .section-content__col._text { padding-left: 0; }
  .section-content._reverse .section-content__visual { left: auto; transform: none; text-align: left; } }

.section-user-stories { padding-top: 60px; padding-bottom: 100px; }

.section-user-stories__title { margin-bottom: 71px; }

.section-user-stories__items { display: flex; align-items: stretch; margin: 0 -14px -28px; flex-wrap: wrap; }

.section-user-stories__items-col { padding: 0 14px; flex: 0 1 33.33%; margin-bottom: 28px; }

.section-user-stories__bottom-text { text-align: center; max-width: 700px; margin-left: auto; margin-right: auto; margin-top: 100px; font-size: 16px; }

@media only screen and (max-width: 1179px) { .section-user-stories__items { margin: 0 -10px -20px; }
  .section-user-stories__items-col { padding: 0 10px; margin-bottom: 20px; } }

@media only screen and (max-width: 991px) { .section-user-stories { padding-bottom: 80px; }
  .section-user-stories__title { margin-bottom: 50px; }
  .section-user-stories__bottom-text { margin-top: 60px; } }

@media only screen and (max-width: 767px) { .section-user-stories__items { margin-bottom: 0; align-items: center; flex-direction: column; }
  .section-user-stories__items-col { flex-basis: auto; width: 300px; margin-bottom: 40px; padding: 0; }
  .section-user-stories__items-col:last-of-type { margin-bottom: 0; } }

.user-story { position: relative; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); padding: 30px 30px 32px; height: 100%; }

.user-story__icon { position: absolute; top: 36px; right: 30px; display: flex; justify-content: center; align-items: center; width: 37px; height: 37px; border-radius: 50%; background-color: rgba(83, 89, 234, 0.15); }

.user-story__icon-house { width: 29.72973%; height: 29.72973%; fill: #5359ea; }

.user-story__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; font-size: 32px; margin-bottom: 15px; padding-right: 50px; }

.user-story__subtitle { font-size: 20px; font-weight: 700; color: #c4c4c4; }

.user-story__list { margin-top: 36px; }

.user-story__list-item { position: relative; align-items: center; margin-bottom: 31px; font-weight: 700; font-size: 18px; padding-left: 50px; }

.user-story__list-item:before { content: ''; display: block; position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 34px; height: 34px; border-radius: 50%; background-image: inline("tick-circle-alt.svg"); background-size: cover; }

.user-story__list-item:last-of-type { margin-bottom: 0; }

.user-story__diagrams { margin-top: 42px; }

.user-story__diagram { margin-bottom: 35px; }

.user-story__diagram:last-of-type { margin-bottom: 0; }

.user-story._dark { background-color: #2b2b2b; color: #fff; }

.user-story._dark .user-story__icon { border: 2px solid #5359ea; background-color: transparent; }

.user-story._dark .user-story__icon-house { fill: #fff; }

.user-story._dark .user-story__subtitle { color: #c0c0cd; }

.user-story._dark .user-story__list-item:before { background-image: inline("tick-circle.svg"); }

@media only screen and (max-width: 1179px) { .user-story { padding: 28px 28px 30px; }
  .user-story__icon { width: 33px; height: 33px; }
  .user-story__title { font-size: 26px; }
  .user-story__list-item { margin-bottom: 25px; font-size: 17px; } }

@media only screen and (max-width: 991px) { .user-story { padding: 25px 22px; }
  .user-story__icon { display: none; }
  .user-story__title { font-size: 22px; padding-right: 0; }
  .user-story__subtitle { font-size: 16px; }
  .user-story__list { margin-top: 20px; }
  .user-story__list-item { font-size: 15px; margin-bottom: 18px; padding-left: 32px; }
  .user-story__list-item:before { width: 22px; height: 22px; }
  .user-story__diagrams { margin-top: 25px; } }

@media only screen and (max-width: 767px) { .user-story { padding: 30px; }
  .user-story__title { font-size: 26px; }
  .user-story__subtitle { font-size: 20px; }
  .user-story__list { margin-top: 30px; }
  .user-story__list-item { font-size: 17px; }
  .user-story__diagrams { margin-top: 30px; } }

.user-story-diagram__bar { background-color: #c0c0cd; height: 8px; border-radius: 8px; margin-bottom: 8px; }

.user-story-diagram__bar._blue { background-color: #5359ea; }

.user-story-diagram__value, .user-story-diagram__caption { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; }

.user-story-diagram__value { font-size: 32px; font-weight: 700; }

.user-story-diagram__caption { font-size: 15px; color: #c0c0cd; }

@media only screen and (max-width: 991px) { .user-story-diagram__value { font-size: 28px; } }

.section-numbers { background-color: #191c20; color: #fff; }

.section-numbers__container { position: relative; height: 100%; padding-top: 118px; padding-bottom: 260px; }

.section-numbers__header { text-align: center; margin-bottom: 137px; }

.section-numbers__subtitle { margin-bottom: 20px; }

.section-numbers__title { font-size: 60px; }

.section-numbers__items { display: flex; flex-wrap: wrap; justify-content: space-between; max-width: 810px; margin-left: auto; margin-right: auto; padding-left: 25px; margin-bottom: -90px; }

.section-numbers__col { width: 320px; margin-bottom: 90px; }

.section-numbers__image { position: absolute; bottom: 0; right: -102px; width: 488px; }

@media only screen and (max-width: 1179px) { .section-numbers__title { font-size: 56px; } }

@media only screen and (max-width: 991px) { .section-numbers__container { padding-bottom: 200px; }
  .section-numbers__header { margin-bottom: 100px; }
  .section-numbers__title { font-size: 48px; }
  .section-numbers__items { max-width: 600px; margin-left: auto; margin-right: auto; }
  .section-numbers__col { width: 50%; display: flex; justify-content: center; } }

@media only screen and (max-width: 767px) { .section-numbers__container { padding-top: 80px; padding-bottom: 180px; }
  .section-numbers__header { margin-bottom: 50px; }
  .section-numbers__title { font-size: 30px; }
  .section-numbers__items { flex-direction: column; margin-bottom: -30px; }
  .section-numbers__col { margin-bottom: 30px; width: 100%; }
  .section-numbers__image { display: none; } }

@media only screen and (max-width: 374px) { .section-numbers__title { font-size: 27px; } }

.section-chosen { padding-top: 137px; padding-bottom: 124px; text-align: center; background: linear-gradient(180deg, rgba(249, 249, 250, 0) 0%, #f9f9fa 47.4%); }

.section-chosen__subtitle { margin-bottom: 22px; }

.section-chosen__title { margin-bottom: 88px; max-width: 76.92308%; margin-left: auto; margin-right: auto; }

.section-chosen__items { display: flex; align-items: center; justify-content: center; }

.section-chosen__item-col { display: flex; justify-content: center; width: 16.66%; padding: 0 10px; font-size: 0; }

.section-chosen__item { max-width: 135px; max-height: 60px; height: auto; }

.section-chosen._dark { background-color: #050505; background-image: none; color: #fff; }

.section-chosen._dark .section-chosen__subtitle { color: #5359ea; }

.section-chosen._dark .section-chosen__item-col { opacity: 0.7; }

.section-chosen._dark .section-chosen__item { filter: brightness(0) invert(1); }

@media only screen and (max-width: 991px) { .section-chosen { padding-top: 80px; padding-bottom: 80px; }
  .section-chosen__item-col { width: 25%; } }

@media only screen and (max-width: 767px) { .section-chosen__title { max-width: none; font-size: 26px; margin-bottom: 50px; }
  .section-chosen__items { flex-direction: column; align-items: center; }
  .section-chosen__item-col { width: 100%; margin-bottom: 40px; }
  .section-chosen__item-col:last-of-type { margin-bottom: 0; }
  .section-chosen__item { max-height: 50px; } }

.section-features { padding-top: 105px; padding-bottom: 112px; background-color: #191c20; color: #fff; border-top: 3px solid #5359ea; }

.section-features__subtitle { text-align: center; margin-bottom: 25px; }

.section-features__title { text-align: center; margin-bottom: 44px; font-size: 60px; }

.section-features__text, .section-features__center-text { text-align: center; font-size: 20px; }

.section-features__text { max-width: 715px; margin-left: auto; margin-right: auto; margin-bottom: 120px; }

.section-features__center-text { max-width: 775px; margin: 115px auto 107px; }

.section-features__items { display: flex; justify-content: space-between; flex-wrap: wrap; max-width: 1055px; width: 90%; margin-left: auto; margin-right: auto; margin-bottom: -60px; }

.section-features__items-col { display: flex; margin-bottom: 60px; padding: 0 5px; width: 28%; }

@media only screen and (max-width: 991px) { .section-features__text, .section-features__center-text { max-width: 640px; }
  .section-features__items { justify-content: center; }
  .section-features__items-col { display: flex; justify-content: center; width: 300px; padding-right: 10px; }
  .section-features__item { width: auto; } }

@media only screen and (max-width: 767px) { .section-features { padding-top: 80px; padding-bottom: 80px; }
  .section-features__title { font-size: 48px; }
  .section-features__text { margin-bottom: 80px; }
  .section-features__items { flex-direction: column; align-items: center; }
  .section-features__items-col { width: 260px; justify-content: flex-start; padding-right: 0; }
  .section-features__center-text { margin: 80px 0; } }

.section-land-reg { padding-top: 105px; padding-bottom: 1px; background-color: #191c20; color: #fff; border-top: 3px solid #5359ea; border-bottom: 3px solid #5359ea; }

.section-land-reg__subtitle { text-align: center; margin-bottom: 25px; }

.section-land-reg__title { text-align: center; margin-bottom: 44px; font-size: 60px; }

.section-land-reg__text, .section-land-reg__center-text { text-align: center; font-size: 20px; }

.section-land-reg__text { max-width: 715px; margin-left: auto; margin-right: auto; margin-bottom: 120px; }

.section-land-reg__center-text { max-width: 775px; margin: 115px auto 107px; }

.section-land-reg__items { display: flex; justify-content: space-between; flex-wrap: wrap; max-width: 1055px; width: 90%; margin-left: auto; margin-right: auto; margin-bottom: -60px; }

.section-land-reg__items-col { display: flex; margin-bottom: 60px; padding: 0 5px; width: 28%; }

@media only screen and (max-width: 991px) { .section-land-reg__text, .section-land-reg__center-text { max-width: 640px; }
  .section-land-reg__items { justify-content: center; }
  .section-land-reg__items-col { display: flex; justify-content: center; width: 300px; padding-right: 10px; }
  .section-land-reg__item { width: auto; } }

@media only screen and (max-width: 767px) { .section-land-reg { padding-top: 80px; padding-bottom: 1px; }
  .section-land-reg__title { font-size: 48px; }
  .section-land-reg__text { margin-bottom: 80px; }
  .section-land-reg__items { flex-direction: column; align-items: center; }
  .section-land-reg__items-col { width: 260px; justify-content: flex-start; padding-right: 0; }
  .section-land-reg__center-text { margin: 80px 0; } }

.section-with-list { padding-top: 142px; padding-bottom: 62px; }

.section-with-list__container { display: flex; justify-content: space-between; }

.section-with-list__grid-col { flex-shrink: 0; }

.section-with-list__title { margin-bottom: 45px; font-size: 60px; }

.section-with-list__visual { position: relative; top: -33px; width: 45.29915%; }

.section-with-list__image { position: absolute; }

.section-with-list__list { margin-bottom: 40px; max-width: 500px; }

.section-with-list__list:last-child { margin-bottom: 0; }

.section-with-list__list._two-cols { max-width: none; }

.section-with-list__text { margin-bottom: 40px; max-width: 490px; }

.section-with-list__text:last-child { margin-bottom: 0; }

.section-with-list__bottom-text { margin-top: 81px; text-align: center; }

.section-with-list._dark { color: #fff; background-color: #050505; }

.section-with-list._visual-offset-top .section-with-list__visual { top: 40px; }

.section-with-list._image-closer .section-with-list__visual { width: 55.55556%; }

.section-with-list._reverse .section-with-list__container { flex-direction: row-reverse; }

.section-with-list._reverse .section-with-list__grid-col { padding-right: 0; width: 42.73504%; }

.section-with-list._reverse .section-with-list__visual { width: 50%; }

.section-with-list._reverse .section-with-list__image { position: relative; left: 100%; transform: translateX(-100%); }

@media only screen and (max-width: 1179px) { .section-with-list__title { font-size: 52px; } }

@media only screen and (max-width: 991px) { .section-with-list { padding-top: 90px; }
  .section-with-list__title { font-size: 44px; }
  .section-with-list__grid-col { width: 57%; }
  .section-with-list__visual { top: 30px; }
  .section-with-list__image { max-width: 150%; }
  .section-with-list._reverse .section-with-list__grid-col { width: 51.28205%; }
  .section-with-list._reverse .section-with-list__visual { width: 45%; } }

@media only screen and (max-width: 767px) { .section-with-list { padding-top: 80px; padding-bottom: 20px; }
  .section-with-list__container { display: block; }
  .section-with-list__grid-col { margin-bottom: 50px; width: auto; }
  .section-with-list__title { font-size: 38px; margin-bottom: 40px; }
  .section-with-list__visual { top: auto; width: auto; }
  .section-with-list__image { position: static; }
  .section-with-list__bottom-text { margin-top: 50px; }
  .section-with-list._visual-offset-top .section-with-list__visual { top: auto; }
  .section-with-list._image-closer .section-with-list__visual { width: auto; }
  .section-with-list._reverse .section-with-list__grid-col, .section-with-list._reverse .section-with-list__visual { width: auto; } }

.section-db-features { padding-top: 70px; padding-bottom: 110px; }

.section-db-features__title { margin-bottom: 112px; text-align: center; max-width: 940px; margin-left: auto; margin-right: auto; }

.section-db-features__title .clip-element { line-height: 1.5; }

.section-db-features__items { display: flex; flex-wrap: wrap; align-items: stretch; margin-bottom: -34px; }

.section-db-features__items-col { width: 25%; padding: 0 17px; margin-bottom: 34px; }

.section-db-features__items-col:nth-of-type(1n) .section-db-features__item:after { background-image: inline("lines1.svg"); width: 139px; }

.section-db-features__items-col:nth-of-type(2n) .section-db-features__item:after { background-image: inline("lines2.svg"); width: 108px; }

.section-db-features__items-col:nth-of-type(3n) .section-db-features__item:after { background-image: inline("lines3.svg"); width: 114px; }

.section-db-features__items-col:nth-of-type(4n) .section-db-features__item:after { background-image: inline("lines4.svg"); width: 144px; }

.section-db-features__item { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; position: relative; font-size: 26px; font-weight: 700; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); padding: 27px 29px 35px; height: 100%; min-height: 257px; line-height: 1.5; }

.section-db-features__item:before { content: ''; display: block; width: 34px; height: 34px; background-image: inline("tick-circle-alt2.svg"); background-size: cover; margin-bottom: 28px; }

.section-db-features__bottom-text { margin-top: 105px; font-size: 16px; text-align: center; }

@media only screen and (max-width: 1179px) { .section-db-features__item { font-size: 24px; min-height: 0; }
  .section-db-features__item:before { margin-bottom: 18px; } }

@media only screen and (max-width: 991px) { .section-db-features__title { margin-bottom: 80px; }
  .section-db-features__items-col { width: 50%; }
  .section-db-features__bottom-text { margin-top: 80px; } }

@media only screen and (max-width: 767px) { .section-db-features__title { margin-bottom: 60px; }
  .section-db-features__items { justify-content: center; }
  .section-db-features__items-col { width: 100%; max-width: 280px; }
  .section-db-features__bottom-text { margin-top: 60px; } }

.section-step-timeline { padding-top: 120px; padding-bottom: 80px; }

.section-step-timeline__title { margin-bottom: 85px; }

@media only screen and (max-width: 991px) { .section-step-timeline { padding-top: 110px; padding-bottom: 50px; }
  .section-step-timeline__title { margin-bottom: 60px; } }

@media only screen and (max-width: 767px) { .section-step-timeline { padding-top: 80px; padding-bottom: 20px; }
  .section-step-timeline__title { margin-bottom: 50px; }
  .section-step-timeline__title br { display: none; } }

.step-timeline { position: relative; }

.step-timeline__items { position: relative; }

@media only screen and (max-width: 991px) { .step-timeline { height: auto; }
  .step-timeline__items { flex-direction: column; justify-content: flex-start; } }

.step-timeline-item { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-weight: 700; display: flex; margin-bottom: 27px; }

.step-timeline-item:last-child { margin-bottom: 0; }

.step-timeline-item__circle { position: relative; top: 3px; display: flex; justify-content: center; align-items: center; height: 34px; width: 34px; border: 2px solid #5359ea; border-radius: 50%; margin-bottom: 36px; color: #a0abb1; flex-shrink: 0; margin-right: 23px; }

.step-timeline-item__text { font-size: 22px; line-height: 1.5; border-bottom: 1px solid #c8d8e0; max-width: 780px; flex: 1; padding-bottom: 30px; }

@media only screen and (max-width: 991px) { .step-timeline-item__text { font-size: 20px; } }

@media only screen and (max-width: 767px) { .step-timeline-item__circle { margin-right: 20px; }
  .step-timeline-item__text { font-size: 18px; } }

.section-big-title { padding: 100px 0 30px; }

.section-big-title__container { display: flex; align-items: center; flex-direction: column; text-align: center; }

.section-big-title__container:after { content: ''; display: block; margin-top: 34px; height: 102px; width: 1px; background-color: #c8d8e0; }

.section-big-title__subtitle { margin-bottom: 28px; }

.section-big-title__title { margin-bottom: 24px; }

/***=== PAGES ===***/
.page-home__section-logos { padding-top: 120px; padding-bottom: 160px; }

@media only screen and (max-width: 1179px) { .page-home__section-logos { padding-top: 120px; padding-bottom: 140px; } }

@media only screen and (max-width: 991px) { .page-home__section-logos { padding-bottom: 120px; } }

@media only screen and (max-width: 767px) { .page-home__section-logos { padding-top: 80px; padding-bottom: 80px; } }

.home-intro { background: #050505 url("/media/img/pattern-background.jpg") no-repeat center top; background-size: cover; height: 808px; }

.home-intro__container { position: relative; }

.home-intro__content { display: flex; flex-direction: column; align-items: flex-start; color: #fff; width: 49.57265%; padding-top: 225px; }

.home-intro__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; letter-spacing: -0.03em; font-size: 65px; line-height: 1.3; margin-bottom: 28px; max-width: 570px; overflow: visible; }

.home-intro__title .clip-element { overflow: visible; margin: -0.2em 0; }

.home-intro__text { color: #c0c0cd; line-height: 1.55; margin-bottom: 34px; width: 100%; }

.home-intro__button { padding-left: 22px; padding-right: 18px; }

.home-intro__video-area { position: absolute; top: 190px; right: -253px; }

@media only screen and (max-width: 1240px) { .home-intro__video-area { right: -300px; } }

@media only screen and (max-width: 1179px) { .home-intro { height: 100vh; min-height: 700px; max-height: 768px; padding-bottom: 120px; }
  .home-intro__title { font-size: 52px; }
  .home-intro__text { max-width: 440px; }
  .home-intro__video-area { right: -200px; } }

@media only screen and (max-width: 991px) { .home-intro { height: auto; min-height: 0; max-height: none; }
  .home-intro__content { width: auto; padding-top: 140px; margin-bottom: 40px; }
  .home-intro__title { font-size: 45px; max-width: 460px; }
  .home-intro__video-area { position: relative; top: auto; right: auto; left: 14px; } }

@media only screen and (max-width: 767px) { .home-intro { padding-bottom: 100px; }
  .home-intro__content { padding-top: 120px; width: auto; max-width: 400px; }
  .home-intro__title { font-size: 36px; } }

.key-features { padding-top: 92px; padding-bottom: 134px; }

.key-features__header { display: flex; margin-bottom: 87px; }

.key-features__title { color: #2b2b2b; max-width: 500px; line-height: 1.3; }

.key-features__text { padding-top: 6px; max-width: 500px; font-size: 22px; font-weight: 500; }

.key-features__items { display: flex; justify-content: space-between; }

@media only screen and (max-width: 991px) { .key-features__text { max-width: 320px; } }

@media only screen and (max-width: 767px) { .key-features { padding-top: 80px; padding-bottom: 80px; }
  .key-features__header { display: block; margin-bottom: 50px; }
  .key-features__title { margin-bottom: 30px; max-width: none; }
  .key-features__text { padding-top: 0; max-width: none; }
  .key-features__items { flex-direction: column; justify-content: flex-start; align-items: center; } }

.key-features-item { position: relative; border-radius: 8px; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); width: 30.76923%; height: 414px; padding: 41px 36px; }

.key-features-item:after { content: ''; display: block; position: absolute; left: 50%; bottom: 29px; transform: translateX(-50%); width: 97px; height: 4px; background-color: rgba(63, 63, 63, 0.1); border-radius: 2px; }

.key-features-item__image-circle { display: flex; justify-content: center; align-items: center; width: 162px; height: 162px; border-radius: 50%; margin-bottom: 27px; background-color: #f9f9fa; margin-left: auto; margin-right: auto; }

.key-features-item__title { font-weight: 700; margin-bottom: 17px; color: #2b2b2b; font-size: 20px; }

.key-features-item__text { position: relative; color: #666; font-size: 18px; line-height: 1.5; padding-left: 26px; font-weight: 500; }

.key-features-item__text:before { content: ''; position: absolute; left: 0; top: 7px; display: block; width: 18px; height: 14px; background-image: inline("tick.svg"); background-size: cover; }

.key-features-item:first-of-type .key-features-item__image { width: 77px; }

.key-features-item:nth-of-type(2) .key-features-item__image { width: 61px; }

.key-features-item:last-of-type .key-features-item__image { width: 52px; }

@media only screen and (max-width: 1179px) { .key-features-item { padding: 32px 26px; }
  .key-features-item__image-circle { width: 130px; height: 130px; margin-bottom: 20px; }
  .key-features-item:first-of-type .key-features-item__image { width: 81.2px; }
  .key-features-item:nth-of-type(2) .key-features-item__image { width: 72.1px; }
  .key-features-item:last-of-type .key-features-item__image { width: 60.9px; } }

@media only screen and (max-width: 991px) { .key-features-item { height: 355px; padding: 28px 24px; }
  .key-features-item__image-circle { width: 110px; height: 110px; }
  .key-features-item__title { font-size: 18px; }
  .key-features-item__text { font-size: 16px; }
  .key-features-item:first-of-type .key-features-item__image { width: 69.6px; }
  .key-features-item:nth-of-type(2) .key-features-item__image { width: 61.8px; }
  .key-features-item:last-of-type .key-features-item__image { width: 52.2px; } }

@media only screen and (max-width: 767px) { .key-features-item { margin-bottom: 50px; width: 280px; height: auto; padding: 30px 30px 70px; }
  .key-features-item:last-of-type { margin-bottom: 0; } }

.competitors-feature { position: relative; z-index: 1; }

.competitors-feature__container { display: flex; position: relative; padding-top: 143px; padding-bottom: 160px; }

.competitors-feature__image { position: absolute; top: 0; left: -23.26389vw; width: 62.29167vw; max-width: 811px; }

@media only screen and (min-width: 1441px) { .competitors-feature__image { left: -335px; } }

.competitors-feature__title { margin-bottom: 27px; max-width: 570px; }

.competitors-feature__text { max-width: 475px; line-height: 1.6; }

@media only screen and (max-width: 991px) { .competitors-feature__container { padding-top: 40px; padding-bottom: 80px; }
  .competitors-feature__image { left: -23.95833vw; } }

@media only screen and (max-width: 767px) { .competitors-feature__container { padding-top: 80px; padding-bottom: 40px; }
  .competitors-feature__image { position: relative; top: auto; transform: translateX(-20%); margin-bottom: 22px; width: 120%; } }

.section-letters { position: relative; padding-top: 151px; padding-bottom: 167px; text-align: center; }

.section-letters:before { content: ''; position: absolute; left: 0; bottom: 0; width: 100%; height: 1015px; background: linear-gradient(180deg, rgba(249, 249, 250, 0) 0%, #f9f9fa 47.4%); }

.section-letters:after { content: ''; display: block; position: absolute; left: 50%; bottom: -96px; width: 1px; height: 162px; background-color: #e0e0e0; z-index: 1; }

.section-letters__text { position: relative; margin-bottom: 73px; font-size: 28px; max-width: 1020px; margin-left: auto; margin-right: auto; z-index: 1; }

.section-letters__box { display: flex; justify-content: center; position: relative; margin-left: auto; margin-right: auto; }

.section-letters__box:after { content: ''; display: block; position: absolute; left: 50%; bottom: 0; width: 80%; height: 80px; margin-left: -40%; background: rgba(5, 5, 5, 0.5); filter: blur(100px); border-radius: 0 0 8px 8px; z-index: 0; will-change: transform; }

.section-letters__box-inner { background: #050505 url("/media/img/pattern-background.jpg") no-repeat center top; background-size: cover; position: relative; padding: 36px 15px; width: 810px; border-top: 3px solid #5359ea; border-radius: 0 0 15px 15px; overflow: hidden; z-index: 1; }

.section-letters__box-inner:before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(to top, #000 0%, transparent 100%); }

.section-letters__box-content { position: relative; color: #fff; font-size: 20px; }

.section-letters__box-content p { margin-bottom: 8px; }

.section-letters__box-content p:last-of-type { margin-bottom: 0; }

@media only screen and (max-width: 1179px) { .section-letters { padding-top: 60px; }
  .section-letters__text { font-size: 24px; max-width: 768px; } }

@media only screen and (max-width: 991px) { .section-letters { padding-bottom: 100px; }
  .section-letters:after { height: 100px; }
  .section-letters__text { font-size: 20px; margin-bottom: 50px; }
  .section-letters__box-inner { border-radius: 0 0 8px 8px; }
  .section-letters__box-content { font-size: 18px; } }

@media only screen and (max-width: 767px) { .section-letters { padding-top: 40px; padding-bottom: 80px; }
  .section-letters__text { margin-bottom: 20px; font-size: 18px; }
  .section-letters__box-content { font-size: 16px; } }

.section-products { padding-top: 92px; padding-bottom: 200px; }

.section-products__grid-col { width: 50%; }

.section-products__header { display: flex; margin-bottom: 93px; }

.section-products__header-text { max-width: 470px; padding-top: 9px; }

.section-products__title { max-width: 475px; }

.section-products__items { display: flex; }

@media only screen and (max-width: 1179px) { .section-products__title { max-width: 400px; }
  .section-products__header-text { max-width: 340px; } }

@media only screen and (max-width: 991px) { .section-products { padding-bottom: 140px; }
  .section-products__header { margin-bottom: 70px; } }

@media only screen and (max-width: 767px) { .section-products { padding-top: 80px; padding-bottom: 80px; }
  .section-products__header { display: block; margin-bottom: 60px; }
  .section-products__header-text { padding-top: 0; }
  .section-products__title { margin-bottom: 30px; }
  .section-products__items { display: block; }
  .section-products__grid-col { width: 100%; }
  .section-products__grid-col._bottom { margin-bottom: 55px; } }

.section-products-item { width: 34.51389vw; max-width: 497px; }

.section-products-item__bar { display: flex; justify-content: space-between; padding-left: 26px; padding-right: 35px; align-items: center; color: #fff; background-color: #050505; height: 75px; border-radius: 8px; margin-bottom: 30px; }

.section-products-item__bar-title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 24px; font-weight: 700; }

.section-products-item__bar-logo { width: 111px; height: 13px; fill: #fff; }

.section-products-item__bar._blue { background-color: #5359ea; }

.section-products-item__text { margin-bottom: 48px; }

@media only screen and (max-width: 1179px) { .section-products-item__bar { height: 66px; padding-left: 20px; padding-right: 30px; }
  .section-products-item__bar-title { font-size: 22px; }
  .section-products-item__bar-logo { width: 100px; }
  .section-products-item__text { font-size: 18px; margin-bottom: 40px; } }

@media only screen and (max-width: 991px) { .section-products-item__bar { height: 60px; }
  .section-products-item__bar-title { font-size: 18px; } }

@media only screen and (max-width: 767px) { .section-products-item { width: auto; max-width: 320px; }
  .section-products-item__bar { margin-bottom: 27px; height: 52px; border-radius: 6px; }
  .section-products-item__text { margin-bottom: 30px; } }

.section-print-postage { padding-top: 180px; padding-bottom: 278px; background-image: linear-gradient(to bottom, #f9f9fa, #fff); }

.section-print-postage__container { position: relative; }

.section-print-postage__content { max-width: 42.73504%; }

.section-print-postage__subtitle { margin-bottom: 30px; }

.section-print-postage__title { margin-bottom: 16px; max-width: 420px; font-size: 40px; }

.section-print-postage__image { position: absolute; right: 110px; top: -30px; }

@media only screen and (max-width: 1179px) { .section-print-postage { padding-bottom: 200px; }
  .section-print-postage__content { max-width: 440px; } }

@media only screen and (max-width: 991px) { .section-print-postage { padding-bottom: 120px; }
  .section-print-postage__title { max-width: 400px; font-size: 36px; }
  .section-print-postage__text { max-width: 320px; }
  .section-print-postage__image { max-width: 300px; width: 100%; top: 30px; right: 40px; } }

@media only screen and (max-width: 767px) { .section-print-postage { padding-top: 80px; padding-bottom: 80px; }
  .section-print-postage__title { font-size: 32px; margin-bottom: 30px; }
  .section-print-postage__text { max-width: 440px; }
  .section-print-postage__image { position: static; margin-bottom: 40px; } }

.about-intro { position: relative; padding-bottom: 17.01389vw; border-bottom: 3px solid #5359ea; }

.about-intro:before { content: ''; display: block; position: absolute; left: 0; top: -11.11111vw; width: 100%; height: calc(100% + 11.11111vw); background-image: url("/media/img/pattern-houses.jpg"); background-size: cover; background-position: center top; background-repeat: no-repeat; }

.about-intro__container { position: relative; z-index: 1; }

.about-intro__header { text-align: center; max-width: 950px; margin-left: auto; margin-right: auto; }

.about-intro__title { position: relative; margin-bottom: 49px; }

.about-intro__title .br-mobile { display: none; }

.about-intro__text { max-width: 650px; margin-left: auto; margin-right: auto; }

@media only screen and (min-width: 1441px) { .about-intro { padding-bottom: 245px; } }

@media only screen and (max-width: 991px) { .about-intro__title { margin-bottom: 30px; }
  .about-intro__text { max-width: 80%; } }

@media only screen and (max-width: 767px) { .about-intro__title .br-mobile { display: inline; }
  .about-intro__title._big { font-size: 32px; } }

.section-text-cta { padding-top: 80px; padding-bottom: 65px; }

.section-text-cta__container { display: flex; }

.section-text-cta__content { position: relative; }

.section-text-cta__text-big { max-width: 430px; }

.section-text-cta__text { max-width: 480px; margin-bottom: 50px; font-size: 17px; }

.section-text-cta__button { position: absolute; bottom: 0; }

@media only screen and (max-width: 991px) { .section-text-cta { text-align: center; }
  .section-text-cta__container { flex-direction: column; align-items: center; }
  .section-text-cta__grid-col { width: auto; padding-right: 0; }
  .section-text-cta__text-big, .section-text-cta__text { margin-bottom: 30px; }
  .section-text-cta__text-big { max-width: 560px; }
  .section-text-cta__text-big br { display: none; }
  .section-text-cta__content { display: flex; flex-direction: column; align-items: center; }
  .section-text-cta__button { position: static; } }

@media only screen and (max-width: 767px) { .section-text-cta__text-big { margin-bottom: 20px; }
  .section-text-cta__text { margin-bottom: 25px; } }

.core-values { padding-top: 67px; padding-bottom: 13.88889vw; }

.core-values__header { margin-bottom: 200px; text-align: center; }

.core-values__subtitle { margin-bottom: 20px; }

.core-values__text { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; font-size: 30px; font-weight: 700; max-width: 760px; margin-left: auto; margin-right: auto; }

@media only screen and (min-width: 1441px) { .core-values { padding-bottom: 200px; } }

@media only screen and (max-width: 991px) { .core-values__header { margin-bottom: 140px; }
  .core-values__subtitle { margin-bottom: 20px; } }

@media only screen and (max-width: 767px) { .core-values { padding-top: 40px; }
  .core-values__header { margin-bottom: 100px; } }

.section-awards { padding-top: 154px; padding-bottom: 90px; }

.section-awards__container { display: flex; flex-wrap: wrap; }

.section-awards__container:after { content: ''; display: block; width: 100%; height: 1px; background-color: #c8d8e0; margin-top: 103px; }

.section-awards__title { max-width: 350px; margin-right: 17.94872%; }

@media only screen and (max-width: 991px) { .section-awards { padding-top: 110px; }
  .section-awards__container:after { margin-top: 80px; } }

@media only screen and (max-width: 767px) { .section-awards { padding-top: 80px; padding-bottom: 100px; }
  .section-awards__container { display: block; }
  .section-awards__container:after { display: none; }
  .section-awards__title { margin-bottom: 50px; } }

.section-awards-item { display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 45px; }

.section-awards-item:after { content: ''; display: block; height: 1px; width: 217px; margin-top: 20px; background-color: #c8d8e0; }

.section-awards-item:last-of-type { margin-bottom: 0; }

.section-awards-item:last-of-type:after { display: none; }

.section-awards-item__link { text-decoration: none; color: #2b2b2b; }

.section-awards-item__link:hover { color: rgba(43, 43, 43, 0.8); }

.section-awards-item__link:hover .section-awards-item__title-circle { transform: translateX(3px); transition-duration: 0.1s; }

.section-awards-item__link:active .section-awards-item__title-circle { transform: translateX(5px); }

.section-awards-item__title { font-family: Gilmer, Arial, sans-serif; letter-spacing: -0.02em; position: relative; display: flex; font-size: 32px; margin-bottom: 20px; max-width: 350px; padding-right: 45px; }

.section-awards-item__title-circle { position: absolute; right: 0; top: -1px; transition: transform 0.35s ease-out; }

.section-awards-item__text { max-width: 355px; }

@media only screen and (max-width: 1179px) { .section-awards-item__title { font-size: 28px; max-width: 320px; } }

@media only screen and (max-width: 991px) { .section-awards-item__title { font-size: 26px; max-width: 280px; } }

@media only screen and (max-width: 767px) { .section-awards-item__title { flex-direction: row-reverse; max-width: none; } }

.section-news { padding-bottom: 120px; }

.section-news__subtitle { margin-bottom: 24px; }

.section-news__title { margin-bottom: 70px; }

.section-news__items { display: flex; flex-wrap: wrap; margin: 0 -24px; }

.section-news__col { width: 33.33%; padding: 0 24px; }

.section-news__text { margin-top: 45px; font-size: 16px; }

@media only screen and (max-width: 991px) { .section-news { padding-bottom: 100px; }
  .section-news__items { margin: 0 -10px; }
  .section-news__col { padding: 0 10px; } }

@media only screen and (max-width: 767px) { .section-news { padding-bottom: 80px; }
  .section-news__subtitle { margin-bottom: 15px; }
  .section-news__title { margin-bottom: 50px; }
  .section-news__items { margin: 0 0 -30px; }
  .section-news__col { width: 100%; padding: 0; margin-bottom: 30px; max-width: 260px; } }

.section-news-item { position: relative; display: flex; flex-direction: column; align-items: flex-start; box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05); height: 377px; padding: 108px 42px 60px; }

.section-news-item__logo { position: absolute; top: 65px; transform: translateY(-50%); }

.section-news-item__link { position: absolute; bottom: 35px; }

.section-news-item__link:hover { opacity: 0.8; }

.section-news-item__link:active { opacity: 0.6; }

@media only screen and (max-width: 991px) { .section-news-item { height: 320px; padding: 110px 23px 30px; }
  .section-news-item__text { font-size: 16px; } }

.case-intro__container { position: relative; display: flex; }

.case-intro__content { display: flex; flex-direction: column; align-items: flex-start; padding-top: 34px; padding-bottom: 60px; padding-right: 60px; width: 56.41026%; }

.case-intro__title { font-size: 48px; margin-bottom: 12px; }

.case-intro__text { max-width: 580px; margin-bottom: 60px; padding-right: 30px; }

.case-intro__visual { position: relative; }

.case-intro__visual-content { position: absolute; left: 0; top: 36px; width: 47.29167vw; }

.case-intro__media { display: block; max-width: 100%; }

@media only screen and (max-width: 1179px) { .case-intro__content { width: 60%; }
  .case-intro__title { font-size: 52px; }
  .case-intro__text { max-width: 550px; }
  .case-intro__visual-content { left: -40px; } }

@media only screen and (max-width: 991px) { .case-intro__content { padding-top: 0; padding-right: 60px; }
  .case-intro__title { font-size: 36px; }
  .case-intro__text { margin-bottom: 45px; } }

@media only screen and (max-width: 767px) { .case-intro { padding-bottom: 60px; }
  .case-intro__container { display: block; }
  .case-intro__content { width: auto; padding-right: 0; }
  .case-intro__title { font-size: 32px; }
  .case-intro__visual-content { position: static; width: 90%; } }

.page-lettings__cta-block { padding-bottom: 110px; }

@media only screen and (max-width: 991px) { .page-lettings__cta-block { padding-bottom: 90px; } }

.page-sales__section-on-market { padding-top: 55px; }
